import React, { useState } from "react";
// import AOS from "aos";
// import "aos/dist/aos.css";

const Life = () => {
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleExpand = (section) => {
    if (expandedSection === section) {
      setExpandedSection(null);
    } else {
      setExpandedSection(section);
    }
  };

  return (
    <>
      <div className="mx-[25px] lg:mx-0 lg:my-[1.5rem] mt-[30px]">
        <h1 className="font-['Roboto'] font-bold lg:text-4xl text-2xl text-center ">
          Life at St. Michael’s
        </h1>
      </div>
      <div className="lg:px-[3rem] 2xl:px-[5rem] transition-all ease-in-out duration-300 font-['Roboto'] lg:ml-[4px] flex flex-col items-center lg:flex-row 2xl:justify-around lg:justify-center lg:gap-[32px]">
        {/* content */}
        <div
          className={`py-6 px-5 mx-4 lg:mx-0 ${
            expandedSection === "section1"
              ? "w-auto lg:w-[35rem] h-auto"
              : "lg:w-[25rem] 2xl:w-[35rem] 2xl:h-[28rem]  lg:h-[28rem]"
          } transition-all ease-in-out duration-300 h-auto mt-[17px] rounded-2xl bg-[#1A2338] text-white flex flex-col justify-center items-center gap-4`}
        >
          {/* Image */}
          <img src="/Group3.png" alt="Group3" className="w-20 h-20 mb-3" />

          {/* Title */}
          <h2 className="text-2xl font-semibold text-white">
            Our Guiding Principles
          </h2>

          <div className="bg-white border border-white w-60 border-1px"></div>

          {/* Description */}
          <p
            className={`text-sm mb-3 lg:text-base 2xl:text-lg text-center ${
              expandedSection === "section1"
                ? "w-full  text-justify"
                : "w-5/6 text-center"
            } text-white font-roboto text-base font-normal leading-6 ${
              expandedSection === "section1"
                ? "your-additional-class-for-section1"
                : "your-additional-class-for-other-sections"
            }`}
          >
            {expandedSection === "section1"
              ? "Our school prioritizes a holistic approach to education, aiming to nurture well-rounded individuals who are intellectually, emotionally, and socially competent. Learning by doing is our educational cornerstone, enabling students to engage with practical experiences that reinforce theoretical knowledge. Through teamwork and a strong emphasis on culture and values, we cultivate an environment where collaboration and ethical conduct pave the way for lifelong success."
              : "Our school champions a holistic education rooted in experiential learning, fostering intellectual, emotional, and social growth"}
          </p>

          {/* Learn More Button */}
          <button
            onClick={() => toggleExpand("section1")}
            className=" font-bold text-center leading-4 text-sm bg-white py-[12px] lg:mb-4 px-[20px] rounded-lg text-[#1A2338] mt-auto"
          >
            {expandedSection === "section1" ? "Close" : "Learn More"}
          </button>
        </div>
        <div
          className={`py-6 px-5 ${
            expandedSection === "section2"
              ? "w-auto lg:w-[35rem] h-auto"
              : "lg:w-[25rem] 2xl:w-[35rem] 2xl:h-[28rem]  lg:h-[28rem]"
          } mx-4 lg:mx-0 mt-[17px] rounded-2xl bg-[#1A2338] text-white flex flex-col items-center justify-center gap-4 transition-all duration-500 ease-in-out`}
        >
          {/* Image */}
          <img src="/Group4.png" alt="Group3" className="w-20 h-20 mb-3" />

          {/* Title */}
          <h2 className="text-2xl font-semibold text-center text-white">
            Our Unique Teaching Methodology
          </h2>
          <div className="bg-white border border-white w-60 border-1px"></div>

          {/* Description */}
          <p
            className={`text-sm mb-3 lg:text-base 2xl:text-lg ${
              expandedSection === "section2"
                ? "w-full  text-justify"
                : "w-5/6 text-center"
            } text-white font-roboto text-base leading-6 ${
              expandedSection === "section2"
                ? "your-additional-class-for-section1"
                : "your-additional-class-for-other-sections"
            }`}
          >
            {expandedSection !== "section2"
              ? "Our Think, Say, and Do methodology melds cognitive development, effective communication, and hands-on learning to"
              : "Our school's unique Think, Say, and Do methodology embodies a threefold approach to education that encourages comprehensive learning and skill development. The Think component focuses on cognitive development and critical reasoning skills, prompting students to delve deep into subject matter and question the status quo. Say emphasizes the importance of effective communication, equipping students with the ability to articulate ideas, lead discussions, and engage meaningfully with others. Lastly, Do incorporates hands-on, practical experiences that allow students to apply their theoretical understanding in real-world scenarios. By interweaving these elements, we create a cohesive educational experience that fosters not only academic excellence but also social responsibility and emotional intelligence. This multifaceted approach effectively prepares our students for the diverse challenges and opportunities of the 21st century."}
          </p>

          {/* Learn More Button */}
          <button
            onClick={() => toggleExpand("section2")}
            className="font-bold text-center leading-4 text-sm bg-white py-[12px] lg:mb-4 px-[20px] rounded-lg text-[#1A2338] mt-auto"
          >
            {expandedSection === "section2" ? "Close" : "Learn More"}
          </button>
        </div>
        <div
          className={`py-6 px-5 ${
            expandedSection === "section3"
              ? "w-auto lg:w-[35rem] h-auto"
              : "lg:w-[25rem] 2xl:w-[35rem] 2xl:h-[28rem]  lg:h-[28rem]"
          } mx-4 lg:mx-0 mt-[17px] rounded-2xl bg-[#1A2338] text-white flex flex-col items-center gap-4 transition-all duration-500 ease-in-out`}
        >
          {/* Image */}
          <img src="/Group5.png" alt="Group3" className="mb-3 w-20 h-20" />

          {/* Title */}
          <h2 className="text-2xl font-semibold text-white">
            Inclusive Education
          </h2>
          <div className="bg-white border border-white w-60 border-1px"></div>

          {/* Description */}
          <p
            className={`text-sm lg:text-base 2xl:text-lg mb-3 ${
              expandedSection === "section3"
                ? "w-full  text-justify"
                : "w-5/6 text-center"
            } text-white font-roboto text-base leading-6 ${
              expandedSection === "section3"
                ? "your-additional-class-for-section1"
                : "your-additional-class-for-other-sections"
            }`}
          >
            {expandedSection !== "section3"
              ? "Our Inclusive Education model celebrates diversity and individual strengths, employing a personalized, multi-disciplinary approach"
              : "Our Inclusive Education model celebrates diversity and individual strengths, employing a personalized, multi-disciplinary approach to cultivate academic excellence and social empathy. We aim to equip students with the skills and understanding necessary for success in a diverse, interconnected world.Our Inclusive Education model celebrates diversity and individual strengths, employing a personalized, multi-disciplinary approach to cultivate academic excellence and social empathy. We aim to equip students with the skills and understanding necessary for success in a diverse, interconnected world.Our Inclusive Education model celebrates diversity and individual strengths, employing a personalized, multi-disciplinary approach to cultivate academic excellence and social empathy. We aim to equip students with the skills and understanding necessary for success in a diverse, interconnected world."}
          </p>

          {/* Learn More Button */}
          <button
            onClick={() => toggleExpand("section3")}
            className="font-bold text-center leading-4 text-sm bg-white py-[12px] lg:mb-4 px-[20px] rounded-lg text-[#1A2338] mt-auto"
          >
            {expandedSection === "section3" ? "Close" : "Learn More"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Life;
