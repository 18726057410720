import React from "react";

const Activityehead = () => {
  return (
    <div
      style={{
        backgroundImage: `url(/ActivityImage.jpg)`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      className="relative lg:w-full lg:h-screen w-full h-[222px] flex justify-start items-center px-[25px]"
    >
      <div id="newsletters"></div>
      <div className="absolute bottom-5 font-['Roboto'] bg-[#1A233880] rounded-2xl p-4">
        <p className=" lg:text-6xl text-lg  font-['Roboto'] font-extrabold text-[#FFA901]">
          Life at St. Michael’s School
        </p>
      </div>
    </div>
  );
};

export default Activityehead;
