import React from "react";

const Privacy = () => {
  return (
    <div
      style={{ fontFamily: " sans-serif" }}
      className="lg:mx-[5rem] mx-[2rem] space-y-6  lg:text-lg lg:mt-6 text-[#1A2338]  font-medium"
    >
      <h1 className="lg:text-2xl  text-lg pt-2 font-bold text-[#1A2338] font-sans">
        Privacy Policy
      </h1>
      <p className="lg:text-4xl text-lg lg:pt-6 font-bold text-[#1A2338] font-sans">
        This Privacy Policy applies to stmichaels-school.in
      </p>
      <p>
        St. Michael’s School recognises the importance of maintaining your
        privacy. We value your privacy and appreciate your trust in us. This
        Policy describes how we treat user information we collect on
        stmichaels-school.in and other offline sources. This Privacy Policy
        applies to current and former visitors to our website and to our online
        customers. By visiting and/or using our website, you agree to this
        Privacy Policy.
      </p>
      <h1 className="text-lg pt-2 font-bold text-[#1A2338] font-sans">
        Information we collect
      </h1>
      <p>
        Contact information. We might collect your name, email, mobile number,
        phone number, street, city, state, pincode, country and ip address.
      </p>
      <p>
        Payment and billing information. We might collect your billing name,
        billing address and payment method when you buy a ticket. We NEVER
        collect your credit card number or credit card expiry date or other
        details pertaining to your credit card on our website. Credit card
        information will be obtained and processed by our online payment partner
        CC Avenue.
      </p>
      <p>
        Information you post. We collect information you post in a public space
        on our website or on a third-party social media site belonging to
        stmichaels-school.in.
      </p>
      <p>
        Demographic information. We may collect demographic information about
        you, events you like, events you intend to participate in, tickets you
        buy, or any other information provided by your during the use of our
        website. We might collect this as a part of a survey also.
      </p>
      <p>
        Other information. If you use our website, we may collect information
        about your IP address and the browser you’re using. We might look at
        what site you came from, duration of time spent on our website, pages
        accessed or what site you visit when you leave us. We might also collect
        the type of mobile device you are using, or the version of the operating
        system your computer or device is running.
      </p>
      <p className="text-lg pt-2 font-bold text-[#1A2338] font-sans">
        We collect information in different ways.
      </p>
      <p>
        We collect information directly from you. We collect information
        directly from you when you register for an event or buy tickets. We also
        collect information if you post a comment on our websites or ask us a
        question through phone or email.
      </p>
      <p>
        We collect information from you passively. We use tracking tools like
        Google Analytics, Google Webmaster, browser cookies and web beacons for
        collecting information about your usage of our website.
      </p>
      <p>
        We get information about you from third parties. For example, if you use
        an integrated social media feature on our websites. The third-party
        social media site will give us certain information about you. This could
        include your name and email address.
      </p>
      <p className="text-lg pt-2 font-bold text-[#1A2338] font-sans">
        Information we collect
      </p>
      <p>
        Contact information. We might collect your name, email, mobile number,
        phone number, street, city, state, pincode, country and ip address.
      </p>
      <p>
        Payment and billing information. We might collect your billing name,
        billing address and payment method when you buy a ticket. We NEVER
        collect your credit card number or credit card expiry date or other
        details pertaining to your credit card on our website. Credit card
        information will be obtained and processed by our online payment partner
        CC Avenue.
      </p>
      <p>
        Information you post. We collect information you post in a public space
        on our website or on a third-party social media site belonging to
        stmichaels-school.in.
      </p>
      <p>
        Demographic information. We may collect demographic information about
        you, events you like, events you intend to participate in, tickets you
        buy, or any other information provided by your during the use of our
        website. We might collect this as a part of a survey also.
      </p>
      <p>
        Other information. If you use our website, we may collect information
        about your IP address and the browser you’re using. We might look at
        what site you came from, duration of time spent on our website, pages
        accessed or what site you visit when you leave us. We might also collect
        the type of mobile device you are using, or the version of the operating
        system your computer or device is running.
      </p>
      <p className="text-lg pt-2 font-bold text-[#1A2338] font-sans">
        We collect information in different ways.
      </p>
      <p>
        We collect information directly from you. We collect information
        directly from you when you register for an event or buy tickets. We also
        collect information if you post a comment on our websites or ask us a
        question through phone or email.
      </p>
      <p>
        We collect information from you passively. We use tracking tools like
        Google Analytics, Google Webmaster, browser cookies and web beacons for
        collecting information about your usage of our website.
      </p>
      <p>
        We get information about you from third parties. For example, if you use
        an integrated social media feature on our websites. The third-party
        social media site will give us certain information about you. This could
        include your name and email address.
      </p>
      <p className="text-lg pt-2 font-bold text-[#1A2338] font-sans">
        Third party sites
      </p>
      <p>
        If you click on one of the links to third party websites, you may be
        taken to websites we do not control. This policy does not apply to the
        privacy practices of those websites. Read the privacy policy of other
        websites carefully. We are not responsible for these third party sites.
      </p>
      <p className="text-lg pt-2 font-bold text-[#1A2338] font-sans">
        Updates to this policy
      </p>
      <p>
        This Privacy Policy was last updated on 12.12.2014. From time to time we
        may change our privacy practices. We will notify you of any material
        changes to this policy as required by law. We will also post an updated
        copy on our website. Please check our site periodically for updates.
      </p>
      <p className="text-lg pt-2 font-bold text-[#1A2338] font-sans">
        {" "}
        Jurisdiction
      </p>{" "}
      <p className="pb-8">
        If you choose to visit the website, your visit and any dispute over
        privacy is subject to this Policy and the website’s terms of use. In
        addition to the foregoing, any disputes arising under this Policy shall
        be governed by the laws of India.
      </p>
    </div>
  );
};

export default Privacy;
