import React, { useEffect } from "react";
import NewsAndEvents from "./News";
import { Link } from "react-router-dom";
// import Activitie0 from "./activitie0";

const Calendaractivity = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const section = document.getElementById(hash.substring(1));
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (
    <div>
      <div id="activity">
        <NewsAndEvents />
      </div>
      <div className="mt-[107px] relative">
        <div className="font-['Roboto'] w-full flex flex-col lg:flex-row items-center bg-[#1A2338]">
          {/* Image */}
          <div className="lg:ml-[17rem] ml-[20px] relative -top-16">
            <img
              src="/image3.png"
              alt="images"
              className="lg:w-[370px] lg:h-[250px] w-[295px] h-[224px] object-cover object-top"
            />
          </div>
          <div id="calender" className="flex flex-col lg:ml-10 ml-[63px]">
            <h1 className="text-white font-semibold lg:text-4xl  text-lg leading-[24px]">
              Yearly Calendar
            </h1>
            <span
              style={{ fontFamily: "Lato, sans-serif" }}
              className="text-lg font-normal text-white lg:mt-4 lg:text-2xl"
            >
              Download our yearly calendar by clicking the below button.
            </span>
            <a href="/School Calendar for 2023-24 UPL.pdf" download>
              <button className="flex mt-4 items-center lg:mb-0 mb-[2rem] justify-center w-[210px] h-[50px] border-[#1A2338] border-solid border rounded-md bg-white gap-2.5  txt-xl font-bold text-[#1A2338] ">
                Download
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="font-['Roboto'] mt-[107px] relative">
        <div className="font-['Roboto'] w-full flex flex-col lg:flex-row-reverse  items-center bg-[#1A2338]">
          {/* Image */}
          <div className="lg:ml-0 ml-[20px] lg:mr-[14rem] mr-[60px] relative -top-16">
            <img
              src="/image4.png"
              alt="images"
              className="lg:w-[370px] lg:h-[250px] w-[295px] h-[224px] object-cover object-top"
            />
          </div>

          {/* Text */}
          <div
            id="news"
            className="flex flex-col px-[25px] lg:ml-[55px] ml-[20px]  mr-[60px] relative "
          >
            <h1 className="text-white font-semibold lg:text-4xl lg:mt-[40px] text-lg leading-[24px]">
              Our Monthly Newsletters
            </h1>
            <span
              style={{ fontFamily: "Lato, sans-serif" }}
              className="text-lg font-normal text-white lg:mt-4 lg:text-2xl"
            >
              Checkout our monthly newsletters by clicking on the links below
            </span>

            {/* Download Button */}
            <Link to="/newsletter">
              <button className="flex mt-4 items-center lg:mb-0 mb-[2rem] justify-center w-[210px] h-[50px] border-[#1A2338] border-solid border font-bold rounded-md bg-white gap-2.5 ">
                Checkout
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendaractivity;
