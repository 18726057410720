import React, { useState } from "react";
import { API_BASE_URL } from "../config";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ReCAPTCHA from "react-google-recaptcha";
const Contactform = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [childStudiesHere, setChildStudiesHere] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });
  const [requirement, setRequirement] = useState("FeedBack");
  const [campus, setCampus] = useState("Suchitra Campus");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (form?.name === "" || form?.email === "" || form.phone === "")
      return toast.error(" All fields are required");

    await saveAboutData();
  };
  const onChange = (value) => {
    setIsChecked(true);
  };
  async function saveAboutData() {
    try {
      const payload = {
        Name: form?.name,
        Phone: form?.phone,
        Email: form?.email,
        Requirement: requirement,
        Campus: campus,
        Message: form?.message,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/userContactData`,
        payload,
        config
      );
      console.log(response);
      if (response?.data?.status) {
        setModalOpen(true);
        toast.success("Submitted Successfully");
      }
    } catch (e) {
      console.log("Error:", e);
      toast.error("An error occurred while updating");
    }
  }
  return (
    <div className="bg-[#fff] lg:w-screen flex flex-col lg:flex-row lg:justify-between mt-8 lg:mt-32 p-6">
      <div className="font-['Roboto'] lg:w-full lg:ml-[5rem] ml-[24px]">
        <p className="mb-6 lg:w-[430px] lg:text-[#A3A3A3] text-[#000] font-['Roboto'] lg:text-4xl  text-lg font-extrabold leading-[130%]">
          Write to us if you have queries/concerns or if you want to give us
          feedback
        </p>
      </div>

      <div className="lg:w-full">
        <div className="font-['Roboto'] flex flex-col space-y-4">
          <div className="flex flex-row space-x-2">
            <input
              type="radio"
              id="doesntStudy"
              name="studyStatus"
              onChange={() => setChildStudiesHere(false)}
            />
            <label
              className="text-black text-xl font-normal font-['Roboto'] leading-[55.31px]"
              htmlFor="doesntStudy"
            >
              My child doesn't study here
            </label>
          </div>

          <div className="flex flex-row space-x-2">
            <input
              type="radio"
              id="studiesHere"
              name="studyStatus"
              onChange={() => setChildStudiesHere(true)}
            />
            <label
              className="text-black text-xl font-normal font-['Roboto'] leading-[55.31px]"
              htmlFor="studiesHere"
            >
              My child studies here
            </label>
          </div>
        </div>
        <form>
          <div className="lg:w-3/4 font-['Roboto'] mb-4 lg:mt-[64px] mt-[37px]">
            <label className="text-black text-xl font-normal font-['Roboto']">
              Status your requirement <span className="text-red-500">*</span>
              <select
                value={requirement}
                onChange={(e) => {
                  setRequirement(e.target.value);
                }}
                className="w-full p-2 mt-2 border rounded bg-zinc-100 border-stone-300"
              >
                <option value="FeedBack">FeedBack</option>
                <option value="A Query">A Query</option>
                <option value="A Concern">A Concern</option>
              </select>
            </label>
          </div>
          <div
            className={`lg:w-3/4 mb-4 ${!childStudiesHere ? "opacity-50" : ""}`}
          >
            <label className=" block text-black text-xl font-normal font-['Roboto'] ">
              Select Campus <span className="text-red-500">*</span>
              <select
                value={campus}
                onChange={(e) => {
                  setCampus(e.target.value);
                }}
                className="w-full p-2 mt-2 border rounded bg-zinc-100 border-stone-300"
                disabled={!childStudiesHere}
              >
                <option value="Suchitra Campus">Suchitra Campus</option>
                {/* <option value="West Marredpally Campus">
                  West Marredpally Campus
                </option> */}
              </select>
            </label>
          </div>

          <div className="mb-4 lg:w-3/4">
            <label className="block text-black text-xl font-normal font-['Roboto']">
              Name<span className="text-red-500">*</span>
              <input
                type="name"
                placeholder=""
                className="w-full p-2 mt-2 border rounded bg-zinc-100 border-stone-300"
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, name: e.target.value };
                  })
                }
              />
            </label>
          </div>

          <div className="mb-4 lg:w-3/4">
            <label className="block text-black text-xl font-normal font-['Roboto']">
              E Mail
              <input
                type="email"
                placeholder=""
                className="w-full p-2 mt-2 border rounded bg-zinc-100 border-stone-300"
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, email: e.target.value };
                  })
                }
              />
            </label>
          </div>

          <div className="mb-4 lg:w-3/4">
            <label className="block text-black text-xl font-normal font-['Roboto']">
              Phone
              <input
                type="tel"
                placeholder=""
                className="w-full p-2 mt-2 border rounded bg-zinc-100 border-stone-300"
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, phone: e.target.value };
                  })
                }
              />
            </label>
          </div>

          <div className="mb-4 lg:w-3/4">
            <label className="block text-black text-xl font-normal font-['Roboto']">
              Message
              <input
                type="text"
                placeholder=""
                className="w-full p-10 mt-2 border rounded bg-zinc-100 border-stone-300"
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, message: e.target.value };
                  })
                }
              />
            </label>
          </div>

          {/* Include your Captcha component here */}
          {/* <div
              className="mb-16 mt-[28px] w-[247px] h-[71px] relative cursor-pointer"
              onClick={() => setIsChecked(!isChecked)}
            >
              <img
                src={isChecked ? "/Robot1.png" : "/Robot.png"}
                alt="Captcha"
              />
            </div> */}
          <div className="mb-16 mt-[28px] w-[247px] h-[71px] relative cursor-pointer">
            <ReCAPTCHA

              sitekey="6LeryEopAAAAAAwb-ZwDu_QisXyLsV01q_49U96E"



              onChange={onChange}
            />
          </div>
          <div className="my-10">
            <button
              onClick={handleSubmit}
              type="submit"
              className={`${isChecked
                ? "pointer-events-auto bg-green-500"
                : "pointer-events-none bg-[#1A2338]"
                } text-white border-1 border border-[#000] px-12 py-3 rounded-lg`}
            >
              Submit
            </button>
          </div>
        </form>
        {isModalOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
            <div
              className="font-['Roboto'] bg-white rounded-xl p-5 w-[1187px] h-[500px]"
              style={{ top: "671px", left: "127px", borderRadius: "16px" }}
            >
              <div className="font-['Roboto'] flex flex-col lg:ml-[6rem] lg:mt-[7rem]">
                <h1
                  className="font-bold text-5xl text-center line-height:1.25rem text-black"
                  style={{ lineHeight: "50px" }}
                >
                  Thank you for writing to us.
                </h1>
                <p
                  className=" mt-4 lg:ml-[10rem] font-normal text-3xl text-[#1A2338]"
                  style={{ lineHeight: "39px" }}
                >
                  Our team will revert to you in 1 working day. If urgent
                  <br />
                  <span className="ml-[4rem]">
                    {" "}
                    you call us during School working hours
                  </span>
                </p>
              </div>
              <button
                className="font-['Roboto'] mt-[4.8rem] mb-[6rem]  px-[50px] py-[10px] ml-[29rem] rounded-xl bg-[#1A2338] text-white "
                onClick={() => setModalOpen(false)}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Contactform;
