import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HiChevronLeft, HiChevronRight, HiX } from "react-icons/hi";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { BsInstagram } from "react-icons/bs";
import { AiOutlineYoutube } from "react-icons/ai";
import { CiFacebook, CiLinkedin } from "react-icons/ci";
import AdmissionPopup from "./admissionPopup";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const [texts, setTexts] = useState([
    "Admission open for AY 24-25 Click here.",
    "Are you a passionate educator? We are hiring. Apply now. Click here.",
    "Latest Newsletter released click here.",
  ]);
  const [links, setLinks] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [toggleSwitch, setToggleSwitch] = React.useState({});
  const navigate = useNavigate();
  const getFeePlaceHolder = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getfeeData`);
      const fetchedData = response.data.data;
      const initialSwitchState = {};
      fetchedData.forEach((slide) => {
        initialSwitchState[slide.id] = slide.Published;
      });
      let eachText = response?.data?.data.map((obj) => obj?.Description);
      setTexts(eachText);
      let eachLink = response?.data?.data.map((obj) => obj.Link);
      setLinks(eachLink);
      setToggleSwitch(initialSwitchState);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getFeePlaceHolder();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 2000);

    return () => clearInterval(interval);
  });
  const [showJobPopup, setShowJobPopup] = useState(false);

  const handleJobClick = () => {
    // Logic to display your popup
    // This could be setting a state that toggles the visibility of a modal component
    setShowJobPopup(true);
  };

  return (
    <>
      {showJobPopup && (
        <AdmissionPopup onClose={() => setShowJobPopup(false)} />
      )}
      <div className="bg-[#1A2338] py-1 lg:h-[150px] flex flex-col justify-center">
        <div className=" font-['Roboto'] lg:flex-row items-center lg:justify-between">
          {!showPopup && (
            <div className="flex flex-row items-center justify-between ">
              {/* Logo */}
              <div className="flex items-center lg:mx-10">
                <a href="/">
                  <img
                    src="/logo.png"
                    alt="logo"
                    className="w-[5rem] h-[4rem] lg:w-[9rem] lg:h-[8rem] mx-2"
                  />
                </a>

                {/* Social Icons */}
                <div className="flex flex-row items-center space-x-3 lg:space-x-5">
                  <a href="https://www.facebook.com/Stmichaelsschoolsuchitra/">
                    <CiFacebook
                      color="#fff"
                      className="w-4 h-4 md:w-6 md:h-6 lg:w-10 lg:h-10"
                    />
                  </a>
                  <a href="https://www.instagram.com/st.michaelsschool_suchitra/">
                    <BsInstagram
                      color="#fff"
                      className="w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8"
                    />
                  </a>
                  <a href="https://www.youtube.com/@st.michaelsschoolgodavarih1821">
                    <AiOutlineYoutube
                      color="#fff"
                      className="w-4 h-4 md:w-6 md:h-6 lg:w-10 lg:h-10"
                    />
                  </a>
                  <a href="https://www.linkedin.com/company/96638703/admin/feed/posts/">
                    <CiLinkedin
                      color="#fff"
                      className="w-4 h-4 md:w-6 md:h-6 lg:w-10 lg:h-10"
                    />
                  </a>
                </div>
              </div>
              <div className="flex items-center ">
                <div className="hidden lg:flex items-center lg:bg-[#1A2338] lg:mt-0 mt-4 text-white">
                  <HiChevronLeft size={24} />{" "}
                  <div className="lg:w-[300px] mx-4 lg:text-xl  text-base font-medium ">
                    {toggleSwitch && (
                      <span
                        key={texts[currentTextIndex]}
                        className="text-lg font-semibold text-center whitespace-normal lg:text-xl typed"
                      >
                        <a
                          href={`${
                            currentTextIndex === ""
                              ? ``
                              : `${
                                  links[currentTextIndex]?.includes("https://")
                                    ? links[currentTextIndex]
                                    : `https://${links[currentTextIndex]}`
                                }`
                          }`}
                          onClick={(e) => {
                            if (
                              links[currentTextIndex].toLowerCase() === "job"
                            ) {
                              e.preventDefault();
                              handleJobClick();
                            } else if (
                              links[currentTextIndex] === "newsletter"
                            ) {
                              e.preventDefault();
                              navigate("/newsletter");
                            }
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="capitalize whitespace-normal"
                        >
                          {texts[currentTextIndex]}
                        </a>
                      </span>
                    )}
                  </div>
                  <HiChevronRight size={24} />
                </div>

                <div className="hidden w-px h-20 bg-white lg:mx-4 lg:flex" />
                <div className="w-full lg:mx-4 lg:space-x-9">
                  <a
                    href="https://parent.neverskip.com/#/auth/login"
                    target="_blank"
                    className="text-[#FFA901] font-semibold text-sm lg:text-xl underline"
                    rel="noreferrer"
                  >
                    Pay Fee
                  </a>
                </div>
                {/* Nav Toggle */}
                <div className="relative lg:mr-[80px] mx-4">
                  <FaBars
                    className="cursor-pointer text-[#fff]"
                    size={32}
                    onClick={() => setShowPopup(true)}
                  />
                </div>
              </div>
            </div>
          )}
          {showPopup && (
            <div className="lg:fixed lg:top-0 lg:right-0 lg:left-0 lg:bottom-0 flex justify-center items-start bg-[rgba(32,38,34,0.2)] backdrop-blur-2xl z-10">
              <div
                style={{
                  background: "rgba(26, 35, 56, 0.80)",
                  backdropFilter: "blur(15.194644927978516px)",
                }}
                className="relative w-full p-4 text-xl font-bold bg-no-repeat bg-cover rounded-lg lg:h-5/6 bg-opacity-70"
              >
                {/* Close Button */}

                <div
                  onClick={() => setShowPopup(false)}
                  className="flex items-center m-5 space-x-4 justify-self-end"
                >
                  <HiX
                    className="ml-auto cursor-pointer text-[#fff]"
                    size={24}
                  />
                  <span className="text-white cursor-pointer">Close</span>
                </div>
                {/* Navigation Items */}
                <div className="flex flex-col space-y-4 lg:flex-row lg:justify-around lg:space-y-0">
                  <div className="text-right">
                    <img
                      src="/logo.png"
                      alt="logo"
                      className="ml-auto w-[6rem] h-[6rem]"
                    />
                    <Link
                      onClick={() => setShowPopup(!showPopup)}
                      to="/"
                      className="block px-4 py-2 text-[#6E99FF] hover:underline"
                    >
                      Home page
                    </Link>
                    <Link
                      onClick={() => setShowPopup(!showPopup)}
                      to="/about"
                      className="block px-4 py-2 text-[#6E99FF] hover:underline"
                    >
                      Governance & Leadership
                    </Link>
                    <Link
                      onClick={() => setShowPopup(!showPopup)}
                      to="/activities"
                      className="block px-4 py-2 text-[#6E99FF] hover:underline"
                    >
                      Life at St. Michael’s
                    </Link>
                    <Link
                      onClick={() => setShowPopup(!showPopup)}
                      to="/admissions"
                      className="block px-4 py-2 text-[#6E99FF] hover:underline"
                    >
                      Admissions
                    </Link>
                    <Link
                      onClick={() => setShowPopup(!showPopup)}
                      to="/contact"
                      className="block px-4 py-2 text-[#6E99FF] hover:underline"
                    >
                      Contact Us
                    </Link>
                  </div>

                  <div className="flex flex-col ">
                    <Link
                      onClick={() => setShowPopup(!showPopup)}
                      to="/activities"
                      className="text-[#6E99FF] ml-[4px] hover:underline"
                    >
                      Campus Buzz
                    </Link>
                    <p
                      onClick={() => setShowPopup(!showPopup)}
                      className="text-[#fff] font-medium text-base mt-[11px] flex flex-row"
                    >
                      <a
                        href="/activities/#activity"
                        className="flex items-center"
                      >
                        <img src="/Events.png" alt="logo" className="mr-4" />
                        Latest Event
                      </a>
                    </p>

                    <p
                      onClick={() => setShowPopup(!showPopup)}
                      className="text-[#fff] font-medium text-base mt-[25px] flex flex-row"
                    >
                      <a
                        href="/activities/#calender"
                        className="flex items-center"
                      >
                        <img src="/calender.png" alt="logo" className="mr-4" />
                        Calendar
                      </a>
                    </p>
                    <p
                      onClick={() => setShowPopup(!showPopup)}
                      className="text-[#fff] font-medium text-base mt-[23px] flex flex-row"
                    >
                      <a href="/activities/#news" className="flex items-center">
                        <img
                          src="/newsletter.png"
                          alt="logo"
                          className="mr-4"
                        />
                        Newsletter Library
                      </a>
                    </p>
                    <p
                      onClick={() => setShowPopup(!showPopup)}
                      className="text-[#fff] font-medium text-base mt-[25px] flex flex-row"
                    >
                      <a
                        href="/activities/#activities"
                        className="flex items-center"
                      >
                        <img
                          src="/governance.png"
                          alt="logo"
                          className="mr-4"
                        />
                        Outdoor Activities
                      </a>
                    </p>

                    <Link
                      onClick={() => setShowPopup(!showPopup)}
                      to="/activities/#future"
                      className="text-[#6E99FF] mt-[31px] ml-[4px] hover:underline"
                    >
                      Future Ready Infra
                    </Link>
                    <p
                      onClick={() => setShowPopup(!showPopup)}
                      className="text-[#fff] font-medium text-base mt-[23px] flex flex-row"
                    >
                      <a
                        href="/activities/#activities"
                        className="flex items-center"
                      >
                        <img src="/classroom.png" alt="logo" className="mr-4" />
                        Digital Classrooms
                      </a>
                    </p>
                    <p
                      onClick={() => setShowPopup(!showPopup)}
                      className="text-[#fff] font-medium text-base mt-[23px]  flex flex-row"
                    >
                      <a
                        href="/activities/#activities"
                        className="flex items-center"
                      >
                        <img src="/workspace.png" alt="logo" className="mr-4" />
                        Google workspace
                      </a>
                    </p>
                    {/* <p
                      onClick={() => setShowPopup(!showPopup)}
                      className="text-[#fff] font-medium text-base mt-[23px] flex flex-row"
                    >
                      <a
                        href="/activities/#activities"
                        className="flex items-center"
                      >
                        <img src="/activity.png" alt="logo" className="mr-4" />
                        Outdoor activities
                      </a>
                    </p> */}
                  </div>
                  <div>
                    <Link
                      onClick={() => setShowPopup(!showPopup)}
                      to="/admissions/#admissions"
                      className="block lg:px-4 py-2 text-[#6E99FF] hover:underline"
                    >
                      Admissions
                    </Link>
                    <p
                      onClick={() => setShowPopup(!showPopup)}
                      className="text-[#fff] font-medium text-base mt-[10px] flex flex-row"
                    >
                      <a
                        href="/admissions/#admission"
                        className="flex items-center"
                      >
                        <img src="/process.png" alt="logo" className="mr-4" />
                        Apply Now
                      </a>
                    </p>
                    <p
                      onClick={() => setShowPopup(!showPopup)}
                      className="text-[#fff] font-medium text-base mt-[20px] flex flex-row"
                    >
                      <a
                        href="https://calendly.com/stmichaelssuchitracal/request-a-callback-from-the-admission-desk"
                        className="flex items-center"
                      >
                        <img
                          src="/feeStructure.png"
                          alt="logo"
                          className="mr-4"
                        />
                        Request a Call
                      </a>
                    </p>
                    <p
                      onClick={() => setShowPopup(!showPopup)}
                      className="text-[#fff] font-medium text-base mt-[20px] flex flex-row"
                    >
                      <a
                        href="/admissions/#admission"
                        className="flex items-center"
                      >
                        <img
                          src="/applicationForm.png"
                          alt="logo"
                          className="mr-4"
                        />
                        Book a Meet
                      </a>
                    </p>
                    <p
                      onClick={() => setShowPopup(!showPopup)}
                      className="text-[#fff] font-medium text-base mt-[20px] flex flex-row"
                    >
                      <a
                        href="https://calendly.com/stmichaelssuchitracal/schedule-a-campus-visit"
                        className="flex items-center"
                      >
                        <img
                          src="/campusVisit.png"
                          alt="logo"
                          className="mr-4"
                        />
                        Book a Campus Visit
                      </a>
                    </p>
                  </div>
                  <div>
                    <Link
                      to="/"
                      className="block px-4 py-2 text-[#6E99FF] hover:underline"
                    >
                      Our school
                    </Link>
                    {/* Wrap images in flex container */}
                    <div className=" lg:space-y-2 flex lg:flex-col gap-[4px] lg:gap-[4px] mt-4 justify-start">
                      <img
                        src="/image25.jpg"
                        alt="logo"
                        className="lg:w-[237px] lg:h-[150px] w-[140px] h-[88px] rounded-lg"
                      />
                      <img
                        src="/image26.jpg"
                        alt="logo"
                        className="lg:w-[237px] lg:h-[150px] w-[140px] h-[88px] rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="h-[80px] text-white lg:hidden flex lg:justify-normal justify-center items-center">
        <div className="flex items-center lg:ml-[50px] lg:bg-[#1A2338] p-4 text-[#1A2338] lg:text-white">
          <HiChevronLeft size={24} />
          <div className="mx-4 text-xl font-normal">
            {toggleSwitch && (
              <span
                key={texts[currentTextIndex]}
                className="text-lg font-semibold text-center capitalize lg:text-xl typed"
              >
                <a
                  href={`https://${links[currentTextIndex]}`}
                  onClick={(e) => {
                    if (links[currentTextIndex].toLowerCase() === "job") {
                      e.preventDefault();
                      handleJobClick();
                    } else if (links[currentTextIndex] === "newsletter") {
                      e.preventDefault();
                      navigate("/newsletter");
                    }
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-base capitalize whitespace-normal"
                >
                  {texts[currentTextIndex]}
                </a>
              </span>
            )}
          </div>
          <HiChevronRight size={24} />
        </div>
      </div>
    </>
  );
};

export default Navbar;
