import React, { useEffect } from "react";
import { useState } from "react";

const Beyondactivity = () => {
  const [expandedSection, setExpandedSection] = useState(null);
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const section = document.getElementById(hash.substring(1));
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  const toggleExpand = (section) => {
    if (expandedSection === section) {
      setExpandedSection(null);
    } else {
      setExpandedSection(section);
    }
  };

  return (
    <div className="flex flex-row justify-center flex-wrap mx-[25px] md:my-10">
      <div className="bg-[#fff]">
        <h1 className="text-center  text-[#1A2338] font-['Roboto']  font-bold lg:text-[2rem] text-2xl lg:mt-0 mt-4">
          Beyond Classroom
        </h1>
      </div>

      <div className="relative w-full h-auto">
        {/* Section 1 */}
        <div className="mx-[25px] 2xl:mx-0 font-['Roboto'] flex flex-col lg:grid lg:grid-cols-2 justify-between lg:items-start ">
          <div className="2xl:w-[500px] flex flex-col 2xl:ml-[79px]">
            <h1 className="text-[#1A2338] font-['Roboto'] font-bold lg:text-[1.5rem]  mt-[23px] lg:mt-[3.8rem]">
              Power of one
            </h1>
            <p
              style={{ fontFamily: "Lato, sans-serif" }}
              className="text-justify lg:mt-[40px] mt-[17px] font-medium lg:text-lg text-lg text-black"
            >
              The "Power of One" program cultivates empathy and communal
              responsibility, showing students that individual acts can make a
              big difference.
            </p>
          </div>
          <div className="flex justify-center">
            <img
              src="/Power.jpg"
              alt="Images"
              className="mt-[23px] lg:mt-[3.8rem] lg:w-[630px] lg:h-[450px]"
            />
          </div>
        </div>
        <div className="mx-[25px] lg:mr-[50px] lg:mx-0 font-['Roboto'] flex flex-col lg:grid lg:grid-cols-2 justify-between items-start ">
          <div className="lg:order-1 flex flex-col lg:ml-[79px] mt-[23px] lg:mt-[3.8rem]">
            <h1 className="text-[#1A2338] font-['Roboto']  font-bold lg:text-[1.5rem] ">
              Enrichment Activities
            </h1>
            <p
              style={{ fontFamily: "Lato, sans-serif" }}
              className="lg:mr-[2rem] lg:mt-[40px] mt-[17px] font-medium lg:text-lg text-lg text-black"
            >
              Our Enrichment Activities extend learning beyond the classroom,
              fostering intellectual curiosity and social skills through arts,
              STEM, and more.
            </p>
          </div>
          <div className="flex justify-center">
            <img
              src="/activity2.jpg"
              alt="Images"
              className="mt-[23px] lg:mt-[3.8rem] lg:w-[630px] lg:h-[450px]"
            />
          </div>
        </div>
        <div className="mx-[25px] 2xl:mx-0 font-['Roboto'] flex flex-col lg:grid lg:grid-cols-2 justify-between items-start">
          <div className="2xl:w-[500px] flex flex-col 2xl:ml-[79px] mt-[23px] ">
            <h1 className="mt-[39px] whitespace-nowrap text-[#1A2338] font-['Roboto'] font-bold lg:text-[1.5rem] ">
              Extra-Curricular Activities
            </h1>
            <p
              style={{ fontFamily: "Lato, sans-serif" }}
              className="lg:mr-[2rem] mt-[17px] font-medium  text-lg text-black lg:mt-[40px]"
            >
              Our Extra-Curricular Activities provide a balanced education by
              developing essential life skills and fostering teamwork and
              leadership.
            </p>
          </div>
          <div className="flex justify-center">
            <img
              src="/activity3.svg"
              alt="Images"
              className="mt-[23px] lg:mt-[3.8rem]"
            />
          </div>
        </div>
      </div>

      <div id="future" className="lg:ml-[72px] lg:my-10">
        <h1 className="font-roboto-sans lg:text-center  mt-[2rem] text-[#1A2338] font-roboto-sans font-bold lg:text-[2rem] text-2xl text-center lg:mb-[1rem]">
          Future-Ready Infra
        </h1>
      </div>

      <div className="relative w-full h-auto">
        {/* Section 1 */}
        <div
          id="activities"
          className="font-['Roboto'] flex flex-col lg:flex-row  lg:items-start"
        >
          {/* Text content for Section 1 */}
          <div
            className={`${
              expandedSection === "section1" ? "w-auto h-auto" : " h-auto"
            } lg:w-1/2 flex flex-col justify-between lg:ml-[79px] ml-[20px] mt-[23px] lg:mt-0`}
          >
            <h1 className="text-[#1A2338] font-['Roboto'] font-bold lg:text-[1.5rem]">
              Digital Classrooms
            </h1>

            {expandedSection === "section1" ? (
              <p
                style={{ fontFamily: "Lato, sans-serif" }}
                className="text-justify lg:w-5/6 lg:mt-[30px] mt-[17px] font-medium text-lg text-black"
              >
                In the 21st-century educational landscape, Smart Panels serve as
                a cornerstone in the transition to digital classrooms. These
                interactive displays go beyond the capabilities of traditional
                whiteboards by offering a range of multimedia functionalities
                that create a vibrant, interactive learning environment. With
                features like multi-touch controls, internet access, and
                integrated software applications, Smart Panels serve as a
                centralized hub for educational resources, thereby setting the
                stage for a more dynamic and immersive educational
                experience.The integration of Smart Panels aligns perfectly with
                the "Think, Say, Do" teaching methodology, emphasizing a
                three-fold approach to education. Firstly, Smart Panels help
                students "Think" critically by enabling teachers to present
                complex concepts through engaging visuals, real-time data, and
                interactive simulations. This facilitates better understanding
                and promotes active cognitive engagement.Lastly, the "Say" and
                "Do" aspects are also enriched through the use of Smart Panels.
                Students can "Say" or articulate their ideas and responses
                directly on the panel, often in collaborative settings,
                enhancing their linguistic skills and encouraging peer-to-peer
                learning. When it comes to "Do," Smart Panels support the
                practical application of knowledge by providing interactive
                exercises and real-world simulations. This allows students not
                just to understand but to apply what they have learned,
                equipping them with crucial 21st-century skills such as
                problem-solving, critical thinking, and digital literacy.
              </p>
            ) : (
              <p
                style={{ fontFamily: "Lato, sans-serif" }}
                className="text-justify lg:w-3/4 lg:mt-[30px] mt-[17px] font-medium text-lg text-black"
              >
                In the 21st-century educational landscape, Smart Panels serve as
                a cornerstone in the transition to digital classrooms. These
                interactive displays go beyond the capabilities of traditional
                whiteboards by offering a range of multimedia functionalities
                that create a vibrant, interactive learning environment.
              </p>
            )}

            <div
              onClick={() => toggleExpand("section1")}
              className="flex mt-[17px] lg:mt-[30px] lg:py-[10px] lg:px-[20px]  px-[4px] py-[8px] w-[104px] lg:w-[144px]   border-[1px] border-solid border-[#1A2338] rounded-lg justify-center items-center  bg-[#FFF]"
            >
              <button className="font-['Roboto'] font-bold  text-lg text-[#1A2338] text-center">
                {expandedSection === "section1" ? "Close" : "Learn More"}
              </button>
            </div>
          </div>

          <div
            id="activities"
            className="flex justify-center items-start lg:mr-[3rem]"
          >
            <img
              src="/Classrooms.png"
              alt="Images"
              className="mt-[23px] lg:mt-0 lg:w-[600px] lg:h-[490px]"
            />
          </div>
        </div>

        <div className="lg:space-x-4 font-['Roboto'] flex flex-col lg:flex-row-reverse justify-between items-center lg:items-start lg:mb-[6rem]  lg:mt-8">
          <div
            className={`${
              expandedSection === "section2" ? "w-auto h-auto" : "w-auto h-auto"
            } lg:w-1/2 flex flex-col mt-[23px] lg:mt-0 lg:ml-[6rem]`}
          >
            <h1 className=" lg:ml-0 ml-[20px] mt-[39px] lg:mt-0 text-[#1A2338] font-['Roboto'] font-bold lg:text-[1.5rem] ">
              Google workspace for edu.
            </h1>
            {expandedSection === "section2" ? (
              <p
                style={{ fontFamily: "Lato, sans-serif" }}
                className="text-justify lg:mt-[30px] mt-[17px] lg:ml-0  ml-[20px] font-medium text-lg text-black"
              >
                Embracing Google Workspace for Education has been like adding a
                set of super-tools to our learning adventure here at school!
                This amazing toolbox helps us do so many fun and important
                things. With Google Classroom, teachers can easily share
                exciting activities and parents can see what we're learning.
                Google Meet lets us have class even when we're at home, so we
                never miss a story or a lesson. And guess what? We can keep all
                our drawings and writings safely stored in Google Drive, just
                like a magic treasure chest! But it's not just about making
                things easier; it's about getting us ready for the big world out
                there. We learn how to work together, talk about our ideas, and
                use cool gadgets—skills that grown-ups say are really important
                for everyone in the 21st century. So, thanks to Google
                Workspace, we're not just having fun, we're becoming little
                experts at things we'll use for the rest of our lives! .
              </p>
            ) : (
              <p
                style={{ fontFamily: "Lato, sans-serif" }}
                className="text-justify lg:mt-[30px] mt-[17px] lg:ml-0 ml-[20px] font-medium text-lg text-black"
              >
                Embracing Google Workspace for Education has been like adding a
                set of super-tools to our learning adventure here at school!
                This amazing toolbox helps us do so many fun and important
                things.
              </p>
            )}

            <div
              onClick={() => toggleExpand("section2")}
              className="lg:mb-[5rem] mt-[17px] lg:mt-[30px]   lg:py-[10px] lg:px-[20px]  px-[4px] py-[8px] lg:w-[144px] border-[1px] border-solid border-[#1A2338] rounded-lg justify-center items-center flex bg-[#FFF] lg:ml-0 ml-4"
            >
              <button className="font-['Roboto'] font-bold text-lg text-[#1A2338] text-center">
                {expandedSection === "section2" ? "Close" : "Learn More"}
              </button>
            </div>
          </div>

          <div className="flex items-start justify-center mb-4 lg:w-1/2 lg:mb-0">
            <img
              src="/Education.jpg"
              alt="Images"
              className="mt-[23px] lg:mt-0 "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Beyondactivity;
