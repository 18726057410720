import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";

const Classroom = () => {
  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024, // Desktop breakpoint, above this size slick is inactive
        settings: "unslick",
      },
      {
        breakpoint: 768, // Tablet screen breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480, // Mobile screen breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [expandedSection, setExpandedSection] = useState(null);

  const toggleExpand = (section) => {
    if (expandedSection === section) {
      setExpandedSection(null);
    } else {
      setExpandedSection(section);
    }
  };
  // The content for each slide
  const slides = [
    <div key={1}>
      <div className="font-['Roboto'] flex flex-col items-center py-[4px] px-[2px] ml-[25px] lg:ml-[57px]">
        <div>
          <img
            className="lg:h-64 lg:w-64 w-[199px] h-[199px]"
            style={{ borderRadius: "199px" }}
            src="/Ellipse 1.png"
            alt="Ellipse"
          />
        </div>
        <div className="flex flex-row items-center mt-7">
          <img className="h-11 w-11" src="/Group6.png" alt="power" />
          <p className=" ml-1 font-bold text-2xl  text-[#1A2338] text-center">
            Extra-curricular Activities
          </p>
        </div>
        <p
          className="lg:text-lg text-md font-normal mt-2 text-[#1A2338]  lg:ml-0 ml-6 text-justify"
          style={{ lineHeight: "26px" }}
        >
          {expandedSection === "section1"
            ? 'Discover the transformative impact of our "Power of One" program, where each student contributes to charitable efforts aimed at helping the needy. Through this initiative, we instill a strong sense of empathy and communal responsibility, teaching children that even individual actions can make a profound difference in the lives of others.'
            : `Our Extra-Curricular Activities provide a balanced education by
                developing essential life skills and fostering teamwork and
                leadership`}
        </p>

        <button
          onClick={() => toggleExpand("section1")}
          className="font-bold text-center leading-4 text-base bg-white py-3 px-5 lg:w-[10rem] mt-4 rounded-lg text-[#1A2338] border border-[#1A2338]"
        >
          {expandedSection === "section1" ? "Close" : "Learn More"}
        </button>
      </div>
    </div>,
    <div key={2}>
      <div className="lg:hidden items-center font-['Roboto'] flex flex-col ml-[25px] lg:ml-[57px]  py-[12px] px-[2px]">
        <img
          className="lg:h-64 lg:w-64 w-[199px] h-[199px] rounded-full  "
          src="/Power.jpg"
          alt="Ellipse"
        />
        <div className="flex flex-row items-center gap-3 space-x-1 mt-7">
          <img className="w-10 h-10" src="/Group7.png" alt="power" />
          <p className="font-bold text-2xl  text-[#1A2338]">Power of One</p>
        </div>
        <p
          className="lg:text-lg text-md  text-justify font-medium  mt-2 text-[#1A2338] lg:ml-0 ml-6"
          style={{ lineHeight: "26px" }}
        >
          {expandedSection === "section2"
            ? "Our school offers a diverse array of Enrichment Activities designed to complement academic learning and nurture well-rounded individuals. From arts and crafts and STEM workshops to physical education and community service projects, these activities provide a platform for students to explore their interests, hone their talents, and engage in constructive collaboration. The aim is to stimulate intellectual curiosity and social engagement, enriching the overall educational experience"
            : `The "Power of One" program cultivates empathy and communal
                responsibility, showing students that individual acts can make a
                big difference.`}
        </p>
        <button
          onClick={() => toggleExpand("section2")}
          className="font-bold text-center leading-4 text-base bg-white py-3 px-5 lg:w-[10rem] mt-4 rounded-lg text-[#1A2338] border border-[#1A2338]"
        >
          {expandedSection === "section2" ? "Close" : "Learn More"}
        </button>
      </div>
    </div>,
    <div key={3}>
      <div className="lg:hidden items-center font-['Roboto'] flex flex-col ml-[25px] lg:ml-[57px]">
        <img
          className="lg:h-64 lg:w-64 w-[199px] h-[199px]"
          src="/Ellipse.png"
          alt="Ellipse"
        />
        <div className="flex flex-row items-center gap-3.5 space-x-1 mt-7">
          <img className="h-11 w-11" src="/Group8.png" alt="power" />
          <p className="font-bold text-2xl  text-[#1A2338]">
            Enrichment Activities
          </p>
        </div>
        <p
          className="lg:text-lg text-md  font-normal text-justify mt-2 text-[#1A2338] lg:ml-0 ml-6"
          style={{ lineHeight: "26px" }}
        >
          {expandedSection === "section3"
            ? "Our school places significant emphasis on Extra-Curricular Activities as an integral part of a balanced education. Ranging from sports teams and music ensembles to coding clubs and environmental initiatives, these activities offer students a venue to develop skills that are not strictly academic but are essential for personal growth and future success. Beyond skill development, extra-curricular involvement also fosters teamwork, leadership, and a sense of community, enriching the student experience beyond the classroom"
            : `Our Enrichment Activities extend learning beyond the
                  classroom, fostering intellectual curiosity and social skills.
                  through arts, STEM, and more.`}
        </p>
        <button
          onClick={() => toggleExpand("section3")}
          className="font-bold text-center leading-4 text-base bg-white py-3 px-5 lg:w-[10rem] mt-4 rounded-lg text-[#1A2338] border border-[#1A2338]"
        >
          {expandedSection === "section3" ? "Close" : "Learn More"}
        </button>
      </div>
    </div>,
  ];
  return (
    <>
      <div className="font-['Roboto'] my-10 lg:mt-28">
        <h1 className="font-bold lg:text-4xl text-3xl text-center lg:my-[1.5rem] mt-10">
          Beyond Classroom
        </h1>
      </div>
      <div className="w-11/12 lg:hidden">
        <Slider {...settings}>{slides}</Slider>
      </div>
      <div className="hidden font-['Roboto'] lg:flex lg:mx-10 2xl:mx-20 justify-center items-start lg:my-5">
        <div
          className={`font-['Roboto'] flex flex-col py-[4px] px-[2px] lg:space-y-5`}
        >
          <div>
            <img
              style={{ borderRadius: "199px" }}
              className="w-[200px] h-[200px] lg:w-[275px] lg:h-[275px]"
              src="/Ellipse 1.png"
              alt="Ellipse"
            />
          </div>
          <div className="flex flex-col gap-3.5 space-x-2 lg:space-x-0 lg:space-y-5 mt-7">
            <img
              className="lg:h-11 lg:w-11 w-[26px] h-[26px]"
              src="/Group6.png"
              alt="power"
            />

            <div className="font-bold lg:text-2xl text-md text-[#1A2338] ">
              <p>Extra-curricular Activities</p>
              <p
                className="lg:text-lg text-md text-justify font-normal mt-2 text-[#1A2338]"
                style={{ lineHeight: "26px" }}
              >
                {expandedSection === "section1"
                  ? 'Discover the transformative impact of our "Power of One" program, where each student contributes to charitable efforts aimed at helping the needy. Through this initiative, we instill a strong sense of empathy and communal responsibility, teaching children that even individual actions can make a profound difference in the lives of others.'
                  : `Our Extra-Curricular Activities provide a balanced education by
                developing essential life skills and fostering teamwork and
                leadership`}
              </p>
            </div>
          </div>
          <button
            onClick={() => toggleExpand("section1")}
            className="font-bold text-center leading-4 text-base bg-white py-3 px-5 lg:w-[10rem] mt-4 rounded-lg text-[#1A2338] border border-[#1A2338]"
          >
            {expandedSection === "section1" ? "Close" : "Learn More"}
          </button>
        </div>
        <div className="self-stretch w-0.5 bg-[#1A2338] mx-[24px] 2xl:mx-[3rem]"></div>
        <div className=" font-['Roboto'] flex flex-col py-[12px] px-[2px] items-start lg:space-y-5 ">
          <img
            src="/Power.jpg"
            alt="Ellipse"
            className="w-[200px] h-[200px] lg:w-[275px] lg:h-[275px] rounded-full"
          />
          <div className="flex flex-col gap-3.5 mt-7 ">
            <img className="h-11 w-11" src="/Group7.png" alt="power" />
            <div className="font-bold text-2xl  text-[#1A2338]">
              Power of One
              <p
                className="lg:text-lg text-base text-justify font-medium  mt-2 text-[#1A2338]"
                style={{ lineHeight: "26px" }}
              >
                {expandedSection === "section2"
                  ? "Our school offers a diverse array of Enrichment Activities designed to complement academic learning and nurture well-rounded individuals. From arts and crafts and STEM workshops to physical education and community service projects, these activities provide a platform for students to explore their interests, hone their talents, and engage in constructive collaboration. The aim is to stimulate intellectual curiosity and social engagement, enriching the overall educational experience"
                  : `The "Power of One" program cultivates empathy and communal
                responsibility, showing students that individual acts can make a
                big difference.`}
              </p>
            </div>
            <button
              onClick={() => toggleExpand("section2")}
              className="font-bold text-center leading-4 text-base bg-white py-3 px-5 lg:w-[10rem] mt-4 rounded-lg text-[#1A2338] border border-[#1A2338]"
            >
              {expandedSection === "section2" ? "Close" : "Learn More"}
            </button>
          </div>
        </div>
        <div className="self-stretch w-0.5 bg-[#1A2338] mx-[24px] 2xl:mx-[3rem]"></div>
        <div className=" font-['Roboto'] flex flex-col lg:space-y-5 ">
          <img
            src="/Ellipse.png"
            alt="Ellipse"
            className="w-[200px] h-[200px] lg:w-[275px] lg:h-[275px]"
          />
          <div className="flex flex-col gap-3.5 lg:space-y-4  mt-7">
            <img className="h-11 w-11" src="/Group8.png" alt="power" />
            <div className="font-bold text-2xl  text-[#1A2338]">
              Enrichment Activities
              <p
                className="text-lg font-normal text-justify mt-2 text-[#1A2338]"
                style={{ lineHeight: "26px" }}
              >
                {expandedSection === "section3"
                  ? "Our school places significant emphasis on Extra-Curricular Activities as an integral part of a balanced education. Ranging from sports teams and music ensembles to coding clubs and environmental initiatives, these activities offer students a venue to develop skills that are not strictly academic but are essential for personal growth and future success. Beyond skill development, extra-curricular involvement also fosters teamwork, leadership, and a sense of community, enriching the student experience beyond the classroom"
                  : `Our Enrichment Activities extend learning beyond the
                  classroom, fostering intellectual curiosity and social skills.
                  through arts, STEM, and more.`}
              </p>
            </div>
            <button
              onClick={() => toggleExpand("section3")}
              className="font-bold text-center leading-4 text-base bg-white py-3 px-5 w-[10rem] rounded-lg text-[#1A2338] border border-[#1A2338]"
            >
              {expandedSection === "section3" ? "Close" : "Learn More"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Classroom;
