import React from "react";

const Guiding = () => {
  return (
    <>
      <div className="!lg:leading-[40px] 2xl:h-[700px] p-5 italic relative w-screen bg-[#1A2338] flex items-center justify-center">
        <div className="text-justify space-y-4 font-[Inter] text-[#FFF] font-Inter italic font-normal  ml-4 lg:text-xl lg:ml-[5rem] lg:mt-[3.5rem] lg:mb-[4rem] lg:mr-[7rem]">
          <p
            style={{ fontFamily: "Lato, sans-serif" }}
            className="lg:text-white font-normal lg:text-lg  lg:leading-[40px]"
          >
            <span className="font-bold lg:text-xl">
              Our core values include :{" "}
            </span>
            <br />
            <br />
            <span className="font-bold lg:text-xl">Excellence : </span>
            We relentlessly pursue excellence in all aspects of education,
            challenging our students to reach their full potential academically,
            creatively, and ethically.
          </p>
          <p
            style={{ fontFamily: "Lato, sans-serif" }}
            className="mt-2 font-normal lg:text-white lg:text-lg lg:mt-6"
          >
            <span className="font-bold lg:text-xl">Innovation : </span> We
            embrace innovation as a cornerstone of education. Our students are
            encouraged to think critically, solve problems, and adapt to the
            ever-evolving digital landscape.
          </p>
          <p
            style={{ fontFamily: "Lato, sans-serif" }}
            className="mt-2 font-normal lg:text-white lg:text-lg lg:mt-6"
          >
            <span className="font-bold lg:text-xl">Integrity : </span> We
            instill in our students the importance of honesty, respect, and
            ethical behavior, ensuring they become responsible and principled
            leaders.
          </p>
          <p
            style={{ fontFamily: "Lato, sans-serif" }}
            className="mt-2 font-normal lg:text-white lg:text-lg lg:mt-6"
          >
            <span className="font-bold lg:text-xl">Community : </span> We foster
            a sense of belonging and interconnectedness among students, parents,
            and the wider community. Together, we create a supportive and
            inclusive environment.
          </p>
          <p
            style={{ fontFamily: "Lato, sans-serif" }}
            className="mt-2 font-normal lg:text-white lg:text-lg lg:mt-6"
          >
            <span className="font-bold lg:text-xl">Collaboration : </span> We
            promote collaboration and partnerships with industry leaders,
            educational experts, and the community to provide students with
            real-world insights and mentorship opportunities. Empowerment: We
            empower our educators through continuous professional development,
            enabling them to employ innovative teaching methods and stay at the
            forefront of educational practices.
          </p>
          <p
            style={{ fontFamily: "Lato, sans-serif" }}
            className="mt-2 font-normal lg:text-white lg:text-lg lg:mt-6"
          >
            <span className="font-bold lg:text-xl">Our mission</span> is to
            guide students at St. Michael's School to become confident,
            adaptable, and purpose-driven individuals. By blending practical
            education with these core values, we equip our students to excel in
            an ever-changing world and to make a positive impact on society.
          </p>
          <p
            style={{ fontFamily: "Lato, sans-serif" }}
            className="mt-2 font-normal lg:text-white lg:text-lg lg:mt-6"
          >
            At St. Michael's School, we are not merely educators; we are
            mentors, guides, and champions of holistic development. Our mission
            is to illuminate the path to success for each student, inspiring
            them to become responsible, innovative, and compassionate global
            citizens.
          </p>
        </div>
      </div>
      <div className="font-['Roboto'] bg-[#fff] lg:mt-[3.9rem] lg:mb-[2rem]">
        <h1 className="text-[#1A2338]  mt-[3rem]  lg:text-[42px]  lg:ml-[76px] lg:mt-[60px] font-['Roboto'] font-bold lg:text-4xl text-2xl text-center">
          Life at St. Michael’s
        </h1>
      </div>
      <div>
        <div className="mx-[25px] font-['Roboto'] flex flex-col lg:grid lg:grid-cols-2 justify-between lg:items-start ">
          <div className="lg:mx-12 2xl:w-[500px] flex flex-col 2xl:ml-[79px]">
            <h1 className="text-[#1A2338] font-['Roboto'] font-bold  lg:text-[40px] mt-[23px] lg:mt-[3.8rem]">
              Guiding Principles
            </h1>
            <p
              style={{ fontFamily: "Lato, sans-serif" }}
              className="lg:w-[30rem] lg:mr-10 text-justify lg:mt-[40px] mt-[17px] font-normal   lg:text-lg text-xs text-black"
            >
              Our school champions a holistic education rooted in experiential
              learning, fostering intellectual, emotional, and social growth
              within a culture of teamwork and ethical values. This integrated
              approach equips students for lifelong success through
              collaboration and practical application of knowledge.
            </p>
          </div>
          <div className="flex justify-center">
            <img
              src="/aboutImage1.svg"
              alt="Images"
              className="mt-[23px] lg:mt-[3.8rem]"
            />
          </div>
        </div>

        <div className="mx-[2.5rem] 2xl:mx-[7.5rem] lg:mr-[50px] font-['Roboto'] flex flex-col lg:grid lg:grid-cols-2 justify-between items-start ">
          <div className="lg:order-1 flex flex-col lg:ml-[4rem] mt-[23px] lg:mt-[3.8rem]">
            <h1 className="text-[#1A2338] font-['Roboto'] font-bold lg:text-[40px]">
              Unique Teaching Methodology
            </h1>
            <p
              style={{ fontFamily: "Lato, sans-serif" }}
              className="text-justify lg:w-[30rem] 2xl:mr-[12rem] lg:mt-[40px] mt-[17px] font-normal lg:text-lg text-xs text-black"
            >
              Our "Think, Say, and Do" methodology melds cognitive development,
              effective communication, and hands-on learning to foster academic
              excellence and social responsibility. This integrative approach
              equips students for the multifaceted challenges and opportunities
              of the 21st century.
            </p>
          </div>
          <div className="flex lg:justify-start justify-center">
            <img
              src="/aboutImage2.svg"
              alt="Images"
              className="mt-[23px] lg:mt-0"
            />
          </div>
        </div>

        <div className="mx-[25px] font-['Roboto'] flex flex-col lg:grid lg:grid-cols-2 justify-between lg:items-start ">
          <div className="lg:mx-[2rem] 2xl:mx-24 2xl:w-[500px] flex flex-col lg:items-start lg:justify-between mt-[23px] ">
            <h1 className="mt-[39px] text-[#1A2338] font-['Roboto'] font-bold lg:text-[40px]">
              Inclusive Education
            </h1>
            <p
              style={{ fontFamily: "Lato, sans-serif" }}
              className="text-justify lg:w-[30rem]  lg:mr-[2rem] mt-[17px] font-normal lg:text-lg text-xs text-black lg:mt-[40px]"
            >
              Our Inclusive Education model celebrates diversity and individual
              strengths, employing a personalized, multi-disciplinary approach
              to cultivate academic excellence and social empathy. We aim to
              equip students with the skills and understanding necessary for
              success in a diverse, interconnected world.
            </p>
          </div>
          <div className="flex justify-center">
            <img
              src="/aboutImage3.jpg"
              alt="Images"
              className="mt-[23px] lg:mt-0 lg:w-[643px] lg:h-[400px]"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Guiding;
