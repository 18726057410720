import React, { useState } from "react";
import FillformPopup from "./fillFormpopup";

const Meet = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handleApplyNowClick = () => {
    setShowPopup(true);
  };

  return (
    <>
      <div>
        <h1 className="lg:ml-[40px] text-center mt-[40px]  font-bold lg:text-xl text-3xl text-[#000] lg:hidden">
          Apply for Admission
        </h1>
      </div>
      <div className="flex flex-col lg:flex-row ">
        <div
          className="lg:w-1/2 2xl:w-1/2 lg:h-[35rem] w-full h-[210px] bg-cover mt-4 lg:mt-0"
          style={{ backgroundImage: "url(meetImage.jpg)" }}
        ></div>
        <div className="lg:flex lg:flex-col lg:space-y-10 lg:justify-center lg:items-start lg:p-16 lg:w-1/2 2xl:w-1/2 lg:h-[35rem] w-full h-[310px] bg-[#1A2338]  lg:mt-0">
          <h1
            className="font-bold text-4xl text-[#fff] hidden lg:block"
            style={{ lineHeight: "46.33px" }}
          >
            Admissions for the Academic Year 2024-2025 are now open.
          </h1>
          <p className="text-justify lg:leading-8 lg:ml-0 lg:mx-10 mx-4 mt-[25px] lg:mt-[2rem] text-[#FFFFFF] font-normal text-base lg:text-xl">
            Parents interested in providing their children with a balanced
            educational experience that fosters both academic and personal
            growth are invited to initiate the admissions process. There are
            multiple avenues to begin your enrollment journey:
          </p>
          <div className="2xl:mt-[10rem] flex flex-row justify-center lg:justify-start mt-[10px] 2xl:gap-[50px] lg:gap-6">
            <div className="flex justify-center items-center font-bold text-sm lg:text-base text-[#1A2338] bg-[#fff] border border-white gap-2.5 rounded-lg mt-5 mx-2 px-2 py-3">
              <button
                onClick={() => handleApplyNowClick()}
                className="whitespace-nowrap"
              >
                Apply Now
              </button>

              <FillformPopup
                setShowPopup={setShowPopup}
                showPopup={showPopup}
              />
            </div>

            <div className="flex items-center justify-center">
              <a
                href="https://calendly.com/stmichaelssuchitracal/request-a-callback-from-the-admission-desk"
                className="font-bold text-sm lg:text-base text-[#1A2338] bg-[#fff] border border-white rounded-lg mt-5 mx-2 px-2 py-3 block text-center"
              >
                Request a call
              </a>
            </div>

            <div className="flex items-center justify-center">
              <a
                href="https://calendly.com/stmichaelssuchitracal/schedule-a-campus-visit"
                className="font-bold text-sm lg:text-base text-[#1A2338] bg-[#fff] border border-white rounded-lg mt-5  px-2 py-3 mr-2 block text-center"
              >
                Schedule a visit
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Meet;
