// import React from "react";
// import GoogleMapReact from "google-map-react";

// const AnyReactComponent = ({ text }) => (
//   <div className="absolute text-center transform -translate-x-1/2 -translate-y-full">
//     <div className="p-1 bg-blue-500 text-white text-sm rounded-full">
//       {text}
//     </div>
//     {/* Google Maps-like Pin SVG */}
//     <svg
//       height="24"
//       viewBox="0 0 24 24"
//       style={{ fill: "#d00", stroke: "white", strokeWidth: "0.5" }}
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7Zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5s2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5Z" />
//     </svg>
//   </div>
// );

// export default function SimpleMap({ location }) {
//   // Function to parse location string to a location object
//   const parseLocation = (locationString) => {
//     const [lat, lng] = locationString.split(",").map(Number);
//     return { lat, lng };
//   };

//   // Using useEffect to update the component when the location prop changes
//   React.useEffect(() => {
//     const newLoc = parseLocation(location);
//     setLoc(newLoc);
//   }, [location]);

//   // Local state to keep track of the location
//   const [loc, setLoc] = React.useState(parseLocation(location));

//   const defaultProps = {
//     zoom: 16,
//   };

//   return (
//     <div className="h-[260px] lg:[280px] lg:w-[630px] w-[330px] rounded-lg">
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: "AIzaSyBtgte60Mmk_7vYYplApfq10IYP8TO3wz4" }}
//         defaultCenter={loc}
//         defaultZoom={defaultProps.zoom}
//       >
//         <AnyReactComponent lat={loc.lat} lng={loc.lng} text="school" />
//       </GoogleMapReact>
//     </div>
//   );
// }

import React from "react";

export default function SimpleMap() {
  return (
    <div className="w-[330px] h-[260px] lg:w-[621px] lg:h-[281px] overflow-hidden rounded-lg">
      <iframe
        title="Location Map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.750422285731!2d78.50536231537508!3d17.44436690438991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9a3bb612cb8f%3A0x60b8fdb25c60c8c4!2sSt.%20Michael&#39;s%20School!5e0!3m2!1sen!2sin!4v1636731265953!5m2!1sen!2sin"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0, borderRadius: "0.5rem" }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </div>
  );
}
