import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { AiOutlineDownload } from "react-icons/ai";
import { IoMdArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const Campus = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div id="campus">
      <div className="font-roboto-sans lg:my-[1.5rem]">
        <h1 className="text-center mt-[10px] mb-[10px] text-[#1A2338] font-Roboto font-bold lg:text-4xl text-3xl leading-20">
          Campus Buzz
        </h1>
      </div>

      {/* Mobile layout with react-slick carousel */}
      <div className="ml-5 flex flex-col justify-center font-['Roboto'] w-[85vw] lg:hidden">
        <Slider {...settings}>
          {[
            {
              imgSrc: "image4.png",
              text: "Check out our newest newsletter to see all the fun Think, Say, Do activities our kids are up to! From solving cool puzzles and sharing their ideas to making awesome crafts, we're helping them learn in the best ways possible.",
              button: "Checkout the newsletter",
            },
            {
              imgSrc: "image3.png",
              text: "Don't miss a moment of your child's educational journey—our school calendar is your go-to guide for all upcoming events, activities, and key academic dates. Stay in the loop with PTA meetings, seasonal celebrations, and more, all designed to enrich your child's learning experience.",
              button: "Download Calender",
            },
          ].map((item, index) => (
            <div className="h-[350px] w-[350px] flex flex-col justify-between items-center mb-10 lg:mb-0">
              <img
                src={item?.imgSrc}
                alt="image4"
                className="rounded-lg h-[300px] w-[350px]"
              />
              <div className="absolute left-0 right-0 bottom-20 "></div>
              <p className="w-[18rem] text-justify  pl-10 lg:pl-0 text-xs leading-14 whitespace-normal absolute bottom-20 text-white lg:mx-[25px] mb-10">
                {item?.text}
              </p>
              <div className="flex justify-center w-full mt-5 ">
                <div className="border border-1 rounded-lg border-[#1A2338]">
                  <Link to="/newsletter/#newsletters">
                    <button className="text-[#1A2338] px-4 py-2  text-sm font-bold">
                      {item?.button}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* The existing layout for desktop screens */}
      <div className="font-['Roboto'] hidden lg:flex px-10 2xl:px-24 lg:space-x-10 lg:ml-0 ml-10 lg:justify-around flex-row mt-10">
        {[
          {
            imgSrc: "desktop-image4.png",
            text: "Check out our newest newsletter to see all the fun Think, Say, Do activities our kids are up to! From solving cool puzzles and sharing their ideas to making awesome crafts, we're helping them learn in the best ways possible.",
          },
          {
            imgSrc: "desktop-image3.png",
            text: "Don't miss a moment of your child's educational journey—our school calendar is your go-to guide for all upcoming events, activities, and key academic dates. Stay in the loop with PTA meetings, seasonal celebrations, and more, all designed to enrich your child's learning experience.",
          },
        ].map((item, index) => (
          <div className="relative w-screen h-[29rem] rounded-lg">
            <div
              className="absolute flex items-center justify-center w-full h-full bg-center bg-cover rounded-lg"
              style={{ backgroundImage: `url(${item.imgSrc})` }}
            >
              <div className="absolute bottom-0 left-0 right-0 rounded-lg h-1/2"></div>
            </div>
            <div className="lg:mr-10 absolute left-[39px] top-[305px] text-white flex items-end justify-center text-center">
              <p
                style={{ fontFamily: "Lato, sans-serif" }}
                className="text-xl text-justify font-semibold leading-[26px]"
              >
                {item.text}
              </p>
            </div>
            <div className="absolute bottom-[-55px] lg:bottom-[-5rem] left-1/2 transform -translate-x-1/2 py-3 px-5 bg-white border border-[#1A2338] rounded-lg flex items-center justify-center">
              <Link to="/newsletter/#newsletters">
                <button className="text-[#1A2338] text-sm font-bold flex items-center justify-between">
                  {index === 0 && "Check the latest newsletter"}
                  {index === 0 && <IoMdArrowForward className="ml-2" />}
                </button>
              </Link>
              {index === 1 && (
                <a href="/School Calendar for 2023-24 UPL.pdf" download>
                  <button className="text-[#1A2338] text-sm font-bold flex space-x-2">
                    <span>Download Calendar</span>
                    <AiOutlineDownload />
                  </button>
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Campus;
