import React, { useState } from "react";

const Director = () => {
  const [selectedTitle, setSelectedTitle] = useState(
    "Administrative Leadership"
  );
  const adminRoles = [
    {
      id: 1,
      role: "Managing Director",
      name: "Mr. J P Reddy",
      image: "image8.png",
      description:
        "Hello and welcome to St. Michael's School, a place where we're deeply committed to nurturing the next generation of learners equipped for the 21st century. Our mission goes beyond textbooks; it's about empowering students with knowledge, skills, and values that transcend conventional education. I'm honored to lead a dedicated team of educators who are not just here to teach but to inspire, challenge, and mentor students. Our goal is to nurture responsible, innovative, and compassionate global citizens. I invite you to join us on this educational journey where innovation and excellence meet to shape the future.",
    },
    {
      id: 2,
      role: "Founding Director",
      name: "Mrs.Patricia Mathew",
      image: "image9.png",
      description:
        "At St. Michael's School, our commitment to values, inclusivity, and fostering a strong sense of community is at the heart of everything we do. I believe wholeheartedly in the importance of upholding integrity, respect, and ethical behavior as the foundation of our educational journey. It's my sincere belief that diversity enriches our learning environment, and I'm deeply dedicated to ensuring that every student feels valued and included. Our school is more than just a place of education; it's a vibrant community where we come together to learn, grow, and support one another. I'm incredibly proud of the strong sense of unity we've built here at St. Michael's School, and I look forward to continuing our journey of shaping compassionate, responsible, and empowered individuals",
    },
    {
      id: 3,
      role: "Executive Director",
      name: "Mrs. Aruna Simham",
      image: "image7.jpeg",
      description:
        "At St. Michael's School, I'm dedicated to hands-on learning, teamwork, and a global outlook. Learning by doing is key – it's where true understanding happens. Our students thrive in activities beyond the classroom, honing their teamwork skills. I'm passionate about instilling a global perspective, preparing our students for a connected world. Together, we're fostering active learners, collaborators, and global citizens",
    },
    {
      id: 4,
      role: "CEO",
      name: "Mr. Mohan Simham",
      image: "image7.jpeg",
      description:
        "At our institution, we pride ourselves on innovation and forward-thinking in education. Our Think-Say-Do teaching methodology empowers students to not only think critically but also articulate their ideas effectively and take meaningful action. We understand that the future is dynamic, so we're dedicated to equipping our students with 21st-century skills that transcend trends. We aim to be not just relevant today, but well into the future, by emphasizing skills that matter regardless of the era. Our focus on skill development is aimed at preparing our students for the unknown, instilling in them the confidence to navigate the everevolving landscape of tomorrow. Together, we're shaping learners who not only adapt but lead with knowledge, expression, and action, ready to thrive in the world ahead.",
    },
  ];
  const operationalRole = [
    {
      id: 1,
      role: "Principal",
      name: "Mrs. Radha Rani",
      image: "image8.svg",
      description:
        "As the Principal of St. Michael's School, I am proud to embrace the vision of the National Education Policy (NEP) that emphasizes holistic education. Our curriculum is thoughtfully designed to not only impart knowledge but also to foster literacy, numeracy, and effective communication skills. We are dedicated to equipping our students with the foundational skills they need to excel academically and thrive in the modern world. With a focus on these key aspects, we are committed to shaping well-rounded individuals who are prepared to face the challenges and opportunities of the 21st century",
    },
    {
      id: 2,
      role: "Vice Principal",
      name: "Mrs. Zarina Joseph",
      image: "image8.svg",
      description:
        "As the Vice Principal of St. Michael's School, my commitment is to enhance educator capability through continuous professional development. We believe in the power of effective instructional design, ensuring that every lesson is engaging and impactful. Our meticulous planning and execution of the academic calendar guarantee a seamless learning experience for our students. Furthermore, we value the collaboration between parents and the school as a vital component of our students' success. Together, we strive to provide an enriching educational journey that nurtures young minds and prepares them for a bright future",
    },
  ];
  const [selectedRole, setSelectedRole] = useState(0);
  const handleTitleClick = (title) => {
    if (title !== "Operating Leadership") {
      setSelectedTitle(title);
    }
  };

  return (
    <div className="lg:mt-10 mt-[2rem]">
      {["Administrative Leadership", "Operating Leadership"].map((title) => (
        <div
          onClick={() => handleTitleClick(title)}
          className={`${
            selectedTitle === title ? "bg-[#1A2338]" : "bg-[#2E416D]"
          } font-['Roboto'] p-4 space-y-4 w-full lg:h-[112px] rounded-[3px] flex-col flex lg:flex-row justify-around items-center px-6`}
        >
          <div className="flex flex-col items-center space-y-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <g clipPath="url(#clip0_0_254)">
                <path
                  d="M18 34.7144C14.2334 30.5676 9.12545 27.8816 3.57426 27.1287C2.94241 27.0588 2.35886 26.7573 1.93622 26.2824C1.51359 25.8075 1.28184 25.1929 1.28569 24.5573V3.85725C1.28567 3.48583 1.3661 3.11882 1.52147 2.78145C1.67683 2.44409 1.90344 2.14439 2.18569 1.90297C2.46297 1.66593 2.78752 1.49061 3.13776 1.38864C3.48801 1.28667 3.85594 1.26039 4.21712 1.31154C9.5253 2.19253 14.3813 4.83793 18 8.82011M18 34.7144V8.82011M18 34.7144C21.7665 30.5676 26.8745 27.8816 32.4257 27.1287C33.0575 27.0588 33.6411 26.7573 34.0637 26.2824C34.4864 25.8075 34.7181 25.1929 34.7143 24.5573V3.85725C34.7143 3.48583 34.6339 3.11882 34.4785 2.78145C34.3231 2.44409 34.0965 2.14439 33.8143 1.90297C33.537 1.66593 33.2124 1.49061 32.8622 1.38864C32.512 1.28667 32.144 1.26039 31.7828 1.31154C26.4747 2.19253 21.6187 4.83793 18 8.82011"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_0_254">
                  <rect width="36" height="36" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {/* Text */}
            <span className="text-white">{title}</span>
          </div>
        </div>
      ))}

      {selectedTitle === "Administrative Leadership" &&
        adminRoles.map((admin, index) => {
          return (
            <div
              key={index}
              className={`${selectedRole === index ? "" : "hidden"}`}
            >
              {/* <h1 className="font-['Roboto']  text-center mt-[2rem] mb-[1rem] lg:mt-[2.8rem] text-[#1A2338] font-bold text-3xl">
                Director and Trustees
              </h1> */}
              <div className="lg:mt-2 bg-white custom-shadow lg:py-[64px] lg:px-[84px] shadow-lg rounded-xl lg:mx-[4rem]">
                {/* Left Section */}
                <div className="font-['Roboto'] flex flex-col bg-white custom-shadow shadow-lg rounded-xl ">
                  {/* Image Section */}
                  <div className="flex flex-col items-center justify-start mb-8 lg:flex-row lg:mx-4 lg:space-x-10">
                    <img
                      className="w-[229px] h-[250px]"
                      src={admin?.image}
                      alt="images"
                    />
                    <div className="flex flex-col items-center w-full mt-2 lg:items-start lg:mt-0">
                      <p className="font-['Roboto'] font-bold text-lg text-black mt-2">
                        {admin?.name}
                      </p>
                      <p className="font-['Roboto'] font-bold text-sm text-[#364C80] lg:mt-2">
                        {admin?.role}
                      </p>
                    </div>
                    <p className=" mt-4 text-justify font-['Roboto'] font-light text-lg text-[#364C80] mx-[25px]">
                      {admin.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      <div className="my-4 grid grid-cols-4 font-['Roboto'] lg:flex lg:justify-center items-start space-x-2 lg:space-x-20 lg:my-6 ">
        {selectedTitle === "Administrative Leadership" &&
          adminRoles.map((admin, index) => {
            return (
              <div
                onClick={() => setSelectedRole(index)}
                className="flex flex-col "
              >
                <h1 className="font-bold text-base lg:text-xl text-[#000] line-height:1.5rem ml-2">
                  {admin?.name}
                </h1>
                <p
                  className={`${
                    index === selectedRole ? "underline" : ""
                  }  font-bold text-base lg:text-xl text-[#364C80] ml-2`}
                >
                  {admin?.role}
                </p>
              </div>
            );
          })}
      </div>
      <div className="my-4 grid grid-cols-2 font-['Roboto'] lg:flex lg:justify-center lg:space-x-20 mx-[25px] lg:my-6">
        {selectedTitle === "Operating Leadership" &&
          operationalRole.map((operational, index) => {
            return (
              <div
                onClick={() => setSelectedRole(index)}
                className="flex flex-col cursor-pointer"
              >
                <h1 className="font-bold text-base lg:text-xl text-[#000] line-height:1.5rem">
                  {operational?.name}
                </h1>
                <p
                  className={`${
                    index === selectedRole ? "underline" : ""
                  } font-bold text-base lg:text-xl text-[#364C80]`}
                >
                  {operational?.role}
                </p>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default Director;
