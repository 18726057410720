import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { toast } from "react-toastify";
import FillformPopup from "./fillFormpopup";

const VisitAdmission = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
const [form, setForm] = useState({
    parentName: "",
    email: "",
    phone: "",
  });
  const [grade, setGrade] = useState("Play School");
  const [showPopup, setShowPopup] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };
  const handleApplyNowClick = () => {
    setShowPopup(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const handleSubmit = async () => {
    if (form?.parentName === "" || form?.email === "" || form?.phone === "")
      return toast.error("Enter fields");
    await saveAboutData();
  };

  const closeThankYou = () => {
    setShowThankYou(false);
  };
  async function saveAboutData() {
    try {
      const payload = {
        Parentname: form?.parentName,
        Mobile: form?.phone,
        Email: form?.email,
        Grade: grade,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/userAdmissionData`,
        payload,
        config
      );
      console.log(response);
      if (response?.data?.status) {
        setShowThankYou(true);
        setModalOpen(false);
        toast.success("Submitted Successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  }
  return (
    <div>
      <div className="flex flex-col">
        <div className="flex flex-col justify-center items-center lg:mx-0 lg:ml-[5rem] mt-[1rem] lg:mt-0">
          <h1 className="text-center text-[#1A2338] font-['Roboto'] lg:text-[40px] text-3xl font-bold">
            Start The Admission Process
          </h1>
        </div>
        <div
          id="admission"
          className="grid grid-cols-1 lg:grid-cols-2 mb-[3rem]"
        >
          <div className="space-y-4 lg:space-y-6 lg:w-3/4 font-['Roboto'] flex flex-col items-center lg:items-start mx-5 lg:mx-[5rem] ">
            <img
              className="mt-[5rem] w-[73px] h-[73px]"
              src="/assignment.svg"
              alt="img"
            />
            <p
              style={{ fontFamily: "Lato, sans-serif" }}
              className="text-justify mt-[1.5rem] font-normal text-xl text-[#000] line-height:1.75rem "
            >
              Complete the Online Application Form: Visit our official
              admissions portal to fill out the online form and submit any
              necessary accompanying documents.
            </p>

            <div
              className="flex self-center mt-2 lg:self-start lg:items-center"
              onClick={handleApplyNowClick}
            >
              <h1 className="pb-1 mr-2 text-2xl font-bold border-b-2">
                Apply Now
              </h1>
              <span className="text-xl">&#x2192;</span>{" "}
            </div>
          </div>
          {showPopup && (
            <FillformPopup setShowPopup={setShowPopup} showPopup={showPopup} />
          )}
          <div className="space-y-4 lg:space-y-6 lg:w-3/4 font-['Roboto'] flex flex-col items-center lg:items-start mx-5 lg:mx-[5rem] ">
            <img
              className="mt-[5rem] w-[73px] h-[73px]"
              src="/phoneImage.svg"
              alt="img"
            />
            <p
              style={{ fontFamily: "Lato, sans-serif" }}
              className=" text-justify mt-[1.5rem] font-normal text-xl text-[#000] line-height:1.75rem "
            >
              Request a Callback from the Admissions Office: If you have
              specific queries or would like to discuss your child's educational
              needs, you can request a callback for a more personalized
              conversation.
            </p>
            <div className="flex self-center mt-2 lg:self-start lg:items-center">
              <a href="https://calendly.com/stmichaelssuchitracal/request-a-callback-from-the-admission-desk">
                {" "}
                <h1 className="pb-1 mr-2 text-2xl font-bold border-b-2">
                  {" "}
                  Request a call
                </h1>
              </a>
              <span className="text-xl">&#x2192;</span>{" "}
            </div>
          </div>
          <div className="space-y-4 lg:space-y-6 lg:w-3/4 font-['Roboto'] flex flex-col items-center lg:items-start mx-5 lg:mx-[5rem] ">
            <img
              className="mt-[5rem] w-[73px] h-[73px]"
              src="/accountImage.svg"
              alt="img"
            />
            <p
              style={{ fontFamily: "Lato, sans-serif" }}
              className="text-justify mt-[1.5rem] font-normal text-xl text-[#000] line-height:1.75rem "
            >
              Visit the Campus: Schedule a visit to gain firsthand insights into
              our facilities, teaching methodology, and community culture.
            </p>
            <div className="flex self-center mt-2 lg:self-start lg:items-center">
              <a href="https://calendly.com/stmichaelssuchitracal/schedule-a-campus-visit">
                <h1 className="pb-1 mr-2 text-2xl font-bold border-b-2 lg:mt-[2.2rem]">
                  Schedule a visit
                </h1>
              </a>
              <span className="text-xl lg:mt-[2.2rem]">&#x2192;</span>{" "}
            </div>
          </div>
          <div className="space-y-4 lg:space-y-6 lg:w-3/4 font-['Roboto'] flex flex-col items-center lg:items-start mx-5 lg:mx-[5rem] ">
            <img
              className="w-[73px] h-[73px] mt-[4rem]"
              src="/inbox.svg"
              alt="img"
            />
            <p
              style={{ fontFamily: "Lato, sans-serif" }}
              className="text-justify font-normal text-xl text-[#000] line-height:1.75rem "
            >
              Write an Email for Information: Should you require additional
              details before making a decision, you are welcome to write an
              email to our admissions office, who will promptly provide all the
              information you need.
            </p>
            <div className="flex self-center lg:self-start lg:items-center">
              <h1
                onClick={openModal}
                className="pb-1 mr-2 text-2xl font-bold border-b-2"
              >
                Email Us
              </h1>
              <span className="text-xl">&#x2192;</span>{" "}
            </div>
            {isModalOpen && (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50 shadow-md backdrop-blur-sm ">
                <div className="relative w-full max-w-lg p-5 bg-white rounded-lg">
                  <div className=" font-['Roboto'] flex flex-col space-y-4">
                    <div className="mb-4">
                      <div class="flex justify-end items-center">
                        <img
                          className="w-[3rem] h-[3rem]"
                          src="/logo.png"
                          alt="Logo"
                        />
                      </div>
                      <label className="block text-xl font-normal text-black">
                        Name of the Parent{" "}
                        <span className="text-red-500">*</span>
                        <input
                          type="text"
                          placeholder="Enter Parent Name"
                          className="w-full p-2 mt-2 border rounded"
                          onChange={(e) =>
                            setForm((prev) => {
                              return { ...prev, parentName: e.target.value };
                            })
                          }
                        />
                      </label>
                    </div>
                    <div className="mb-4">
                      <label className="block text-xl font-normal text-black">
                        Mobile Number <span className="text-red-500">*</span>
                        <input
                          type="text"
                          placeholder="Enter your Number"
                          className="w-full p-2 mt-2 border rounded"
                          onChange={(e) =>
                            setForm((prev) => {
                              return { ...prev, phone: e.target.value };
                            })
                          }
                        />
                      </label>
                    </div>
                    <div className="mb-4">
                      <label className="block text-xl font-normal text-black">
                        Email ID <span className="text-red-500">*</span>
                        <input
                          type="email"
                          placeholder="Enter your Email"
                          className="w-full p-2 mt-2 border rounded"
                          onChange={(e) =>
                            setForm((prev) => {
                              return { ...prev, email: e.target.value };
                            })
                          }
                        />
                      </label>
                    </div>
                    <div className="relative">
                      <label className="block text-xl font-normal text-black">
                        Which class admission are you looking for
                        <select
                          onChange={(e) => setGrade(e.target.value)}
                          className="w-full p-2 mt-2 capitalize border rounded"
                        >
                          <option value="Play School">Play School</option>
                          <option value="pre-primary school">
                            pre-primary school
                          </option>
                          <option value="primary School">primary School</option>
                        </select>
                      </label>
                    </div>
                    <div className="flex justify-between lg:pt-2">
                      <div className="flex justify-between">
                        <button
                          onClick={closeModal}
                          className="bg-[#fff] py-[8px] px-[20px] text-[#1A2338] font-bold ml-[2rem] lg:ml-0 rounded-lg border border-1 border-[#1A2338]"
                        >
                          Close
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={handleSubmit}
                          className="bg-[#1A2338] text-white py-[8px] px-[20px] rounded-lg"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {showThankYou && (
              <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
                <div className="font-['Roboto'] bg-white rounded-lg p-5 max-w-lg w-full relative text-center space-x-4s">
                  <h2 className="text-4xl  font-bold mt-[10rem]">Thank You</h2>
                  <p className="font-['Roboto']  text-2xl font-medium mb-4">
                    We will call you back.
                  </p>
                  <button
                    onClick={closeThankYou}
                    className="text-[#1A2338] mt-[8rem] font-semibold border border-1 border-[#1A2338] rounded-lg px-3 py-2 "
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitAdmission;
