import React from "react";
import Activityehead from "../Activityhead";
import Beyondactivity from "../Beyondactivity";
import Calendaractivity from "../Calenderactivity";

const Activities = () => {
  return (
    <div>
      <Activityehead />
      <Calendaractivity />
      <Beyondactivity />
    </div>
  );
};

export default Activities;
