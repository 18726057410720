import React, { useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { toast } from "react-toastify";
const AdmissionPopup = ({ onClose }) => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    qualifications: "",
    experience: "",
    resume: "",
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        setForm((prev) => {
          return { ...prev, resume: reader.result };
        });
      };
      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    }
  };
  async function handleSubmit(e) {
    e.preventDefault();
    if (
      form?.name === "" ||
      form?.email === "" ||
      form?.qualifications === "" ||
      form?.experience === "" ||
      form?.resume === ""
    )
      return toast.error("All fields are required");

    await saveAboutData();
  }
  async function saveAboutData() {
    try {
      const payload = {
        Name: form?.name,
        Mobile: form?.mobile,
        Email: form?.email,
        Qualifications: form?.qualifications,
        Experience: form?.experience,
        Resume: form?.resume,
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/userData`,
        payload,
        config
      );
      if (response?.data?.status) {
        toast.success("Submitted Successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  }
  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 !z-[100000]">
      <div className="flex flex-col items-center justify-center min-h-screen overflow-hidden backdrop-blur-sm">
        <div className="px-6 py-4 m-auto bg-white rounded-md lg:w-1/2 lg:px-10 ">
          <div class="flex justify-end items-center">
            <img className="w-[3rem] h-[3rem]" src="/logo.png" alt="Logo" />
          </div>

          <form className="space-y-4">
            <div className="">
              <label
                htmlFor="name"
                className="block text-sm font-semibold text-gray-800"
              >
                Name*
              </label>
              <input
                type="name"
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, name: e.target.value };
                  })
                }
                className="block w-full px-2 py-2 lg:px-4 lg:py-2  text-[#000] bg-white border rounded-md"
              />
            </div>
            <div className="">
              <label
                for="text"
                className="block text-sm font-semibold text-gray-800"
              >
                Mobile Number*
              </label>
              <input
                type="text"
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, mobile: e.target.value };
                  })
                }
                className="block w-full px-2 py-2 lg:px-4 lg:py-2  text-[#000] bg-white border rounded-md"
              />
            </div>
            <div className="">
              <label
                for="email"
                className="block text-sm font-semibold text-gray-800"
              >
                Email ID*
              </label>
              <input
                type="email"
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, email: e.target.value };
                  })
                }
                className="block w-full px-2 py-2 lg:px-4 lg:py-2  text-[#000] bg-white border rounded-md"
              />
            </div>
            <div className="">
              <label
                for="text"
                className="block text-sm font-semibold text-gray-800"
              >
                Qualifications*
              </label>
              <input
                type="text"
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, qualifications: e.target.value };
                  })
                }
                className="block w-full px-2 py-2 lg:px-4 lg:py-2  text-[#000] bg-white border rounded-md"
              />
            </div>
            <div className="">
              <label
                for="text"
                className="block text-sm font-semibold text-gray-800"
              >
                Years of teaching experience*
              </label>
              <input
                type="text"
                onChange={(e) =>
                  setForm((prev) => {
                    return { ...prev, experience: e.target.value };
                  })
                }
                className="block w-full px-2 py-2 lg:px-4 lg:py-2  text-[#000] bg-white border rounded-md"
              />
            </div>

            <div className="" id="pdf">
              <label
                htmlFor="pdf"
                for="email"
                className="block text-sm font-semibold text-gray-800 "
              >
                Upload resume*
                <input
                  type="file"
                  onChange={(e) => handleImageChange(e)}
                  className="block w-full px-2 py-2 lg:px-4 lg:py-2  text-[#000] bg-white border rounded-md"
                />
              </label>
            </div>

            <div className="flex justify-between">
              <div className="flex justify-between">
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className={` text-[#1A2338] font-bold border border-1 border-[#1A2338] rounded-lg px-8 lg:px-12 py-3`}
                >
                  Submit
                </button>
              </div>
              <div>
                <button
                  onClick={onClose}
                  className="text-[#1A2338] font-bold border border-1 border-[#1A2338] rounded-lg lg:px-12 py-3 px-8 "
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdmissionPopup;
