import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_URL } from "../config";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
const FillformPopup = ({ setShowPopup, showPopup }) => {
  const [grade, setGrade] = useState("Nursery");
  const [valueDate, setValueDate] = useState("");
  const [form, setForm] = useState({
    Childname: "",
    DateofBirth: valueDate,

    Admission: grade,
    Campus: "Suchitra",
    CurrentSchool: "",
    Location: "",
    Fathernumber: "",
    Mothernumber: "",
    EmailId: "",
  });

  const closeModal = () => {
    setShowPopup(false);
  };
  async function handleSubmit(e) {
    e.preventDefault();
    if (
      form?.Childname === "" ||
      form?.Location === "" ||
      form?.Fathernumber === "" ||
      form?.EmailId === ""
    )
      return toast.error(" All fields are required");
    e.preventDefault();
    await saveAboutData();
  }
  async function saveAboutData() {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        `${API_BASE_URL}/formPopupData`,
        form,
        config
      );
      console.log("resp", response);
      if (response?.data?.status) {
        toast.success("Submitted Successfully");
      }
    } catch (e) {
      console.log("Error:", e);
    }
  }

  useEffect(() => {
    const today = new Date();
    const formattedValueDate = [
      today.getFullYear(),
      String(today.getMonth() + 1).padStart(2, "0"),
      String(today.getDate()).padStart(2, "0"),
    ].join("-");

    setValueDate(formattedValueDate);
    setForm((prevForm) => ({ ...prevForm, DateofBirth: formattedValueDate }));
  }, []);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setValueDate(newDate);
    setForm((prevForm) => ({ ...prevForm, DateofBirth: newDate }));
  };

  return (
    <>
      <Transition appear show={showPopup} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div className="fixed inset-0 overflow-y-auto backdrop-blur-sm">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div className=" font-['Roboto'] flex flex-col lg:space-y-1 space-y-2 ">
                    <img
                      className="w-[3rem] h-[3rem] flex self-end"
                      src="/logo.png"
                      alt="i"
                    />
                    <div>
                      <label className="block text-base font-normal text-black">
                        Name of the Child{" "}
                        <span className="text-red-500">*</span>
                        <input
                          type="text"
                          className="w-full p-1 border rounded"
                          onChange={(e) =>
                            setForm((prev) => {
                              return { ...prev, Childname: e.target.value };
                            })
                          }
                        />
                      </label>
                    </div>
                    <div>
                      <label className="block text-base font-normal text-black">
                        Date of Birth <span className="text-red-500">*</span>
                        <input
                          value={valueDate}
                          type="date"
                          id="birthday"
                          className="w-full p-1 border rounded"
                          placeholder="dd-mm-yyyy"
                          onChange={handleDateChange}
                        />
                      </label>
                    </div>
                    <div className="relative">
                      <label className="block text-base font-normal text-black">
                        Seeking admission into:
                        <span className="text-red-500">*</span>
                        <select
                          onChange={(e) => setGrade(e.target.value)}
                          className="w-full p-1 capitalize border rounded"
                        >
                          <option value="Nursery">Nursery</option>
                          <option value="LKG">LKG</option>
                          <option value="UKG">UKG</option>
                          <option value="Grade1">Grade1</option>
                          <option value="Grade2">Grade2</option>
                          <option value="Grade3">Grade3</option>
                          <option value="Grade4">Grade4</option>
                          <option value="Grade5">Grade5</option>
                        </select>
                      </label>
                    </div>

                    <div className="relative">
                      <label className="block text-base font-normal text-black">
                        Campus<span className="text-red-500">*</span>
                        <select
                          onChange={(e) =>
                            setForm((prev) => {
                              return { ...prev, Campus: e.target.value };
                            })
                          }
                          className="w-full p-1 capitalize border rounded"
                        >
                          <option value="Suchitra">Suchitra</option>
                        
                        </select>
                      </label>
                    </div>

                    <div>
                      <label className="block text-base font-normal text-black">
                        Current School <span className="text-red-500">*</span>
                        <input
                          onChange={(e) =>
                            setForm((prev) => {
                              return { ...prev, CurrentSchool: e.target.value };
                            })
                          }
                          type="text"
                          className="w-full p-1 border rounded"
                        />
                      </label>
                    </div>
                    <div>
                      <label className="block text-base font-normal text-black">
                        Location <span className="text-red-500">*</span>
                        <input
                          onChange={(e) =>
                            setForm((prev) => {
                              return { ...prev, Location: e.target.value };
                            })
                          }
                          type="text"
                          className="w-full p-1 border rounded"
                        />
                      </label>
                    </div>
                    <div>
                      <label className="block text-base font-normal text-black">
                        Fathers Mobile Number{" "}
                        <span className="text-red-500">*</span>
                        <input
                          onChange={(e) =>
                            setForm((prev) => {
                              return { ...prev, Fathernumber: e.target.value };
                            })
                          }
                          type="text"
                          className="w-full p-1 border rounded"
                        />
                      </label>
                    </div>
                    <div>
                      <label className="block text-base font-normal text-black">
                        Mothers Mobile Number{" "}
                        <span className="text-red-500">*</span>
                        <input
                          onChange={(e) =>
                            setForm((prev) => {
                              return { ...prev, Mothernumber: e.target.value };
                            })
                          }
                          type="text"
                          className="w-full p-1 border rounded"
                        />
                      </label>
                      <div>
                        <label className="block text-base font-normal text-black">
                          Email ID for correspondence{" "}
                          <span className="text-red-500">*</span>
                          <input
                            onChange={(e) =>
                              setForm((prev) => {
                                return { ...prev, EmailId: e.target.value };
                              })
                            }
                            type="text"
                            className="w-full p-1 border rounded"
                          />
                        </label>
                      </div>
                    </div>

                    <div className="flex justify-between lg:pt-2">
                      <div className="flex justify-between">
                        <button
                          onClick={closeModal}
                          className="bg-[#fff] py-[8px] px-[20px] text-[#1A2338] font-bold ml-[2rem] lg:ml-0 rounded-lg border border-1 border-[#1A2338]"
                        >
                          Close
                        </button>
                      </div>
                      <div>
                        <button
                          onClick={handleSubmit}
                          className="bg-[#1A2338] text-white py-[8px] px-[20px] rounded-lg"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default FillformPopup;
