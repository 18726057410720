import axios from "axios";
import React from "react";
import { API_BASE_URL } from "../config";
import { useState } from "react";
import { useEffect } from "react";

const Abouthead = () => {
  // const [toggleSwitch, setToggleSwitch] = useState(false);
  // const [activeSlideId, setActiveSlideId] = useState(1);
  const [image, setImage] = useState(null);
  const getAboutData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getaboutData`);
      setImage(response.data.data?.Photo);
      // setActiveSlideId(response.data.data?.id);
      // setToggleSwitch(response.data.data?.Published);
    } catch (e) {
      console.log("err", e);
    }
  };
  useEffect(() => {
    getAboutData();
  }, []);
  return (
    <div className="relative w-full">
      {image ? (
        <img src={image} alt="images" className="w-screen" />
      ) : (
        <img src="/Aboutimage.jpg" alt="images" className="w-screen" />
      )}
      <div className="absolute bottom-12 lg:mx-12 mx-[25px]">
        <div className="bg-[#1A233880] bg-opacity-30 rounded-2xl p-4 2xl:p-6 2xl:space-y-10 lg:space-y-6 py-4 px-4">
          <p className="font-['Roboto'] text-white lg:text-[32px] font-bold text-sm">
            GOVERNANCE & LEADERSHIP
          </p>

          <p
            id="about"
            className="font-roboto-sans lg:text-[4rem] text-xl font-Roboto font-extrabold text-[#FFA901]"
          >
            About us
          </p>
          <p
            style={{ fontFamily: "Lato, sans-serif" }}
            className="hidden lg:block font-[Inter] text-lg lg:text-xl italic  text-white"
          >
            At St. Michael's School, we are driven by a steadfast commitment to
            nurturing 21st-century learners through practical education and a
            deep-rooted set of core values.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Abouthead;
